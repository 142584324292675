@import '../../../styles/colors.scss';

.loader-circle {
  position: relative;
  width: 70px;
  height: 70px;
  margin: auto;
}
.loader-circle:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.loader-circle .circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.loader-circle .circular .path {
  stroke: $primary;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}
.loader-circle.dark .circular .path {
  animation: dash 1.5s ease-in-out infinite, colorDark 6s ease-in-out infinite;
}
.loader-circle.light .circular .path {
  animation: dash 1.5s ease-in-out infinite, colorLight 6s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $primary;
  }
  40% {
    stroke: $accent;
  }
  66% {
    stroke: $primary;
  }
  80%,
  90% {
    stroke: $accent;
  }
}

@keyframes colorDark {
  0% {
    stroke: #000000;
  }
  100% {
    stroke: #000000;
  }
}

@keyframes colorLight {
  0% {
    stroke: #ffffff;
  }
  100% {
    stroke: #ffffff;
  }
}