section.partners {
  padding: 30px 0;
}

section.partners .container {
  display: flex;
  justify-content: space-between;
}

section.partners .rcs_buttonSet {
  display: none;
}

section.partners .rcs_dotsSet {
  display: none;
}

section.partners .itemWrapper {
  height: 50px;
  background: transparent;
}

section.partners .itemWrapper img {
  max-width: 200px;
  object-fit: contain;
  filter: grayscale(1);
  opacity: .5;
  will-change: opacity, filter;
  transition: .25s ease-in-out;
}

section.partners .itemWrapper img:hover {
  opacity: 1;
  filter: grayscale(0);
}

section.partners .rcs_sliderBox {
  height: 100px !important;
  background: #ffffff !important;
  position: relative;
}

section.partners .rcs_sliderBox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 50px;
  background: linear-gradient(to right, #ffffff, transparent);
}

section.partners .rcs_sliderBox::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  width: 50px;
  background: linear-gradient(to left, #ffffff, transparent);
}
