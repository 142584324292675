@import '../../../styles/colors.scss';

section.statistics {
  position: relative;
  z-index: 2;
  padding-top: 75px;
  padding-bottom: 75px;
  overflow: hidden;
  display: flex;
  margin: 0 150px;
}

section.statistics .stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50% - 60px);
  height: 200px;
  position: relative;
  user-select: none;
  animation-fill-mode: forwards;
}

section.statistics .stat-item:hover .stat-item__icon,
section.statistics .stat-item:hover .stat-item__description {
  opacity: .5;
}
section.statistics .stat-item:hover .stat-item__value {
  transform: scale(1.1);
}

section.statistics .stat-item .stat-item__icon-wrapper {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
}

section.statistics .stat-item .stat-item__icon {
  height: 100%;
  transition: .25s ease-in-out;
}

section.statistics .stat-item .stat-item__value {
  display: flex;
  align-items: center;
  color: $primary-light;
  font-size: 50px;
  font-family: Arial;
  font-weight: 800;
  white-space: nowrap;
  letter-spacing: -2px;
  margin-top: 10px;
  transition: .4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
section.statistics .stat-item .stat-item__value span {
  font-size: 30px;
  margin-left: 5px;
}

section.statistics .stat-item .stat-item__description {
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 400;
  padding: 5px 15px;
  transition: .25s ease-in-out;
}


@media (min-width: 1700px) {
  section.statistics {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}

@media (max-width: 1300px) {
  section.statistics {
    padding-top: 50px;
    padding-bottom: 25px;
  }
  section.statistics .stat-item .stat-item__value {
    font-size: 40px;
  }
  section.statistics .stat-item .stat-item__icon-wrapper {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 992px) {
  section.statistics {
    flex-wrap: wrap;
    padding-top: 50px;
    padding-bottom: 0px;
  }
  section.statistics .stat-item {
    width: 50%;
  }
  section.statistics .stat-item:nth-child(n+5) {
    display: none;
  }
  section.statistics .stat-item:nth-child(1) {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  section.statistics .stat-item:nth-child(2) {
    border-bottom: 1px solid #ddd;
  }
  section.statistics .stat-item:nth-child(3) {
    border-right: 1px solid #ddd;
  }
  section.statistics .stat-item .stat-item__value {
    font-size: 50px;
  }
  section.statistics .stat-item .stat-item__icon-wrapper {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 650px) {
  section.statistics {
    margin: 0;
  }
}

@media (max-width: 576px) {
  section.statistics .stat-item .stat-item__value {
    font-size: 40px;
  }
  section.statistics .stat-item .stat-item__icon-wrapper {
    width: 25px;
    height: 25px;
  }
}
