@import '../../../styles/colors.scss';

.news-wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

section.news .section__name {
  padding-bottom: 15px;
}

section.news .new {
  display: block;
  padding-bottom: 15px;
  color: $font-color;
  text-decoration: none;
}

section.news .new:hover .new__title::before {
  transform: scaleX(3);
}

section.news .new .new__title {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin: 3px 0;
  position: relative;
}

section.news .new .new__title .number {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  padding: 0 10px;
  position: relative;
  transition: .15s ease-in-out;
}

section.news .new:hover .new__title .number {
  color: white;
}

section.news .new:hover .new__title .number::after {
  transform: scaleX(1);
}

section.news .new .new__title .number::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: $accent-dark;
  transform-origin: 100% 0;
  transform: scaleX(0);
  transition: .15s ease-in-out;
}

section.news .new .new__title .bar {
  width: 2px;
  margin-right: 10px;
  background-color: $accent-dark;
  transform-origin: 0 0;
  transition: .25s ease-in-out;
}

section.news .new .new__excerpt {
  font-weight: 300;
}

section.news .images {
  position: relative;
  transform-style: preserve-3d;
}

section.news .images .new__image {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 350px;
  object-fit: cover;
  background-color: #eee;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  transition: .5s ease-in-out, opacity .15s ease-in-out .35s;
}
section.news .images .new__image.fade-out {
  opacity: 0;
}

section.news .images .new__image.new__image--first {
  transform: translate3d(-60%, -40%, 1px);
}

section.news .images .new__image.new__image--last {
  transform: translate3d(-40%, -60%, -1px);
  animation: gotoLast .5s ease-in-out forwards;
}

@media (max-width: 1400px) {
  section.news .images .new__image {
    width: 400px;
    height: 275px;
    left: 50%;
  }
}

@media (max-width: 1200px) {
  .news-wrapper {
    padding-bottom: 50px;
  }
  .news-wrapper .box.box-1 {
    top: 50%;
  }
  section.news .images .new__image {
    width: 300px;
    height: 206.25px;
  }
}

@media (max-width: 992px) {
  .news-wrapper {
    padding-top: 0;
  }
  .news-wrapper .lines {
    display: none;
  }
}

@media (max-width: 480px) {
  section.news .new .new__title {
    font-size: 18px;
  }
  section.news .new .new__excerpt {
    font-size: 15px;
    text-align: justify;
  }
}

@keyframes gotoLast {
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}