@import url('https://fonts.googleapis.com/css?family=Raleway:200,300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,800');
@import url('https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:400,500,600');

@import './colors.scss';


body {
  font-family: 'Raleway';
}
body.disable-scroll {
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
}

h1 {
  font-size: 32px;
  margin-bottom: 25px;
}

@media (max-width: 576px) {
  h1 {
    font-size: 27px;
  }
}

.mw-1200 {
  max-width: 1140px;
}
.mw-1000 {
  max-width: 1000px;
}

section {
  padding: 50px 0;
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 7.5px;
}
*::-webkit-scrollbar-track {
  background-color: #ffffff;
}
*::-webkit-scrollbar-thumb {
  background-color: $dark;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #546475;
}

button {
  outline: none !important;
}
.my-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  padding-left: 17.5px;
  padding-right: 17.5px;
  height: 37.5px;
  margin: 10px 0;
  border: none;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  background-color: $primary;
  text-decoration: none;
  cursor: pointer;
  -moz-user-select: none;
  user-select: none;
  transition: .2s ease-out;
}

.my-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
  transition: .15s ease-out;
}

.my-btn:hover {
  text-decoration: none;
  color: #ffffff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
}

.my-btn:hover::before {
  opacity: 1;
}

.my-btn:active {
  transform: scale(.97);
  box-shadow: none;
}

.my-btn.disabled {
  background-color: #d4d4d4;
  color: #525761;
  pointer-events: none;
}

.my-btn--light {
  background-color: transparent;
  color: $dark;
  font-weight: 500;
  box-shadow: none;
  background-color: transparent;
  border: 2px solid $dark;
}

.my-btn--light::before {
  background-color: rgba(0, 0, 0, 0.05);
}

.my-btn--light:hover {
  color: #000000;
  box-shadow: none;
}

.my-btn--light:active {
  background-color: #ffffff;
  color: #000000;
  box-shadow: none;
}

.my-btn--white {
  background-color: transparent;
  color: white;
  font-weight: 500;
  box-shadow: none;
  background-color: transparent;
  border: 2px solid white;
}

.my-btn--white::before {
  background-color: rgba(255, 255, 255, 0.05);
}

.my-btn--white:hover {
  color: white;
  box-shadow: none;
}

.my-btn--white:active {
  background-color: #ffffff;
  color: #000000;
  box-shadow: none;
}

.header-sep {
  background-color: $accent-dark;
  width: 30px;
  height: 2px;
  margin: 10px auto 30px auto;
}


/* Form */
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-control {
  background-color: #fafafa;
  border-color: #e9e9e9;
  border-radius: 20px;
}


/* ANIMATION CLASSES ============================================================== */
/* FADE-IN ========================= */
.fade-in {
  animation: fade-in 250ms ease-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* GROW-IN BOTTOM ========================= */
.grow-in-bottom {
  transform-origin: center bottom;
  animation: grow-in-bottom-animation ease-in-out;
}

@keyframes grow-in-bottom-animation  {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* GROW-IN LEFT ========================= */
.grow-in-left {
  transform-origin: 0 center;
  animation: grow-in-left-animation ease-in-out;
}

@keyframes grow-in-left-animation  {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* GROW-IN RIGHT ========================= */
.grow-in-right {
  transform-origin: 100% center;
  animation: grow-in-right-animation ease-in-out;
}

@keyframes grow-in-right-animation  {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* SLIDE-IN LEFT ========================= */
.slide-in-left {
  transform-origin: 100% center;
  animation: slide-in-left-animation ease-in-out;
}

@keyframes slide-in-left-animation  {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform:  translateX(0);
  }
}

/* SLIDE-IN RIGHT ========================= */
.slide-in-right {
  transform-origin: 100% center;
  animation: slide-in-right-animation ease-in-out;
}

@keyframes slide-in-right-animation  {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform:  translateX(0);
  }
}

/* SLIDE-IN BOTTOM ========================= */
.slide-in-bottom {
  transform-origin: 100% center;
  animation: slide-in-bottom-animation ease-in-out;
}

@keyframes slide-in-bottom-animation  {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform:  translateY(0);
  }
}

/* SLIDE-IN TOP ========================= */
.slide-in-top {
  transform-origin: 100% center;
  animation: slide-in-top-animation ease-in-out;
}

@keyframes slide-in-top-animation  {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform:  translateY(0);
  }
}

.material-icons {
  /* Support for IE */
  font-feature-settings: 'liga';
}