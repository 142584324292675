.home {
  position: relative;
}

.home .lines {
  position: absolute;
  top: 0;
  left: 150px;
  z-index: -1;
  width: calc(100% - 300px);
  height: 100%;
}

.home .lines .line {
  position: absolute;
  top: -100px;
  width: 1px;
  height: 110%;
  background-color: rgba(0, 0, 0, 0.075);
}

.home .lines .line.line-1 {
  left: 0;
}
.home .lines .line.line-2 {
  left: 20%;
}
.home .lines .line.line-3 {
  left: 40%;
}
.home .lines .line.line-4 {
  right: 40%;
}
.home .lines .line.line-5 {
  right: 20%;
}
.home .lines .line.line-6 {
  right: 0;
}

@media (max-width: 992px) {
  .home .lines {
    display: none;
  }
}