.sidenav {
  position: fixed;
  height: 100%;
  width: 350px;
  max-width: 100%;
  transform: translateX(100%);
  z-index: 999;
  top: 0;
  right: 0;
  background-color: #2f3c4a;
  transition: 0.5s;
}
.sidenav::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  box-shadow: 0 0 200px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}
.sidenav.visible {
  transform: translateX(0);
}
.sidenav.visible::before {
  opacity: 1;
}
.sidenav .top-wrapper {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 10px;
}
.sidenav .top-wrapper.big {
  padding: 12px 10px;
}
.sidenav .top-wrapper.big .col {
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidenav .top-wrapper.big .col .username {
  margin: 0;
  color: #000;
}
.sidenav .top-wrapper .top-menu-item {
  justify-content: center;
  background-color: #eee;
  margin-right: 10px;
  padding: 6px 18px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
}
.sidenav .top-wrapper .top-menu-item:hover {
  background-color: #f5f5f5;
}
.sidenav a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  padding: 18px 0;
  white-space: nowrap;
  background-color: #546475;
  border-top: 1px solid #3d4c5c;
  transition: 0.05s ease-in-out;
}
.sidenav a:hover {
  background-color: #4d5c6b;
}
.sidenav a i {
  width: 20px !important;
  font-size: 20px;
  margin: 0 30px 0 20px;
  opacity: 0.25;
  transition: 0.05s ease-in-out;
}
.sidenav a i.arrow {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  opacity: 0.15;
}
.sidenav a:hover i.arrow {
  opacity: 0.5;
}
.sidenav hr {
  background-color: transparent;
  margin: 3px;
}
.sidenav .close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  font-size: 50px;
  font-weight: 300;
  padding-bottom: 5px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  transition: 0.1s ease-in-out;
}
.sidenav .close-btn:hover {
  opacity: 0.5;
  background-color: rgba(255, 255, 255, 0.25);
}
