.btn-loader {
  display: flex;
  align-items: center;
  position: relative;
  width: 150px;
  margin-right: auto;
  margin-left: auto;
}

.btn-loader.success {
  background-color: rgba(198, 255, 0, 0.65);
}

.btn-loader.fail {
  background-color: rgba(255, 0, 0, 0.65);
}

.btn-loader p {
  margin: 0;
  transition: .15s ease-in-out;
}
.btn-loader p.hide {
  opacity: 0;
}

.btn-loader .check, .btn-loader .fail {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  transition: .15s ease-in-out;
}
.btn-loader .check.hide, .btn-loader .fail.hide {
  transform:  translate(-50%) scale(.5);
  opacity: 0;
}

.btn-loader .loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.btn-loader .loader-circle {
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  transition: .15s ease-in-out;
}
.btn-loader .loader-circle.hide {
  transform:  translate(-50%, -50%) scale(.5);
  opacity: 0;
}

.btn-loader .loader-circle .circular {
  height: 35%;
  width: 35%;
}