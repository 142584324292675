@import '../../styles/colors.scss';

.products-container {
  max-width: 900px;
  padding: 0;
}

.products {
  max-width: 900px;
  min-height: 400px;
  margin: 0 auto 50px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.product-item {
  position: relative;
  width: calc(50% - 20px);
  padding: 15px;
  margin: 10px;
  display: flex;
  border: 1px solid #dddddd;
  text-decoration: none;
  overflow: hidden;
  will-change: border-color, box-shadow, transform;
  transition: .25s ease-in-out;
}

.product-item:hover {
  border-color: #444444;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  transform: scale(1.01);
  text-decoration: none;
}
.product-item:hover .product-item-button {
  transform: translateY(0);
}

.product-item .title {
  margin-left: 15px;
  color: $font-color;
}

.product-item img {
  background-color: #ffffff;
  width: 100px;
  height: 80px;
  object-fit: cover;
}

.product-item .product-item-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: $accent-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  transform: translateY(40px);
  transition: .25s ease-in-out;
}

.product-item .product-item-button i {
  color: white;
}


@media (max-width: 650px) {
  .product-item {
    width: 100%;
  }
}


.product-parent-link {
  color: $font-color;
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}

.product-parent-link:hover {
  text-decoration: none;
  color: $font-color;
}

.product-parent-link .icon-wrapper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: -8px;
  transition: .15s ease-in-out;
}

.product-parent-link:hover .icon-wrapper {
  background-color: rgba(0, 0, 0, 0.1);
}

.product-parent-link:active .icon-wrapper {
  background-color: $accent-dark;
}

.product-parent-link:active .icon-wrapper i {
  color: white;
}

.product-parent-link .icon-wrapper i {
  width: 25px;
  height: 25px;
}