@import '../../../styles/colors.scss';

.categories-wrapper  {
  position: relative;
}

.categories-wrapper .categories-wrapper-bg {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 50%;
  height: calc(100% - 150px);
  background-color: $accent-dark;
}

.categories {
  display: flex;
  margin-top: -60px;
  padding: 100px 150px 60px 150px;
  position: relative;
  overflow: hidden;
}

.categories .swiper-container {
  width: 100%;
}

.categories .swiper-wrapper {
  display: flex;
}

.categories .category {
  flex-grow: 1;
  flex-basis: 0;
  height: 65vh;
  position: relative;
  z-index: 1;
  background-color: #eeeeee;
  cursor: pointer;
  will-change: width;
  transition: .5s ease-in-out;
}

.categories .category::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom, rgba(255,255,255,0.5), transparent);
}

.categories .category.active {
  flex-grow: 8;
}

.categories .category.active:hover {
  flex-grow: 8;
}

.categories .category.not-active .title {
  opacity: 0 !important;
}

.categories .category.not-active::before {
  opacity: .9;
}

.categories .category.active:hover::before,
.categories .category.active::before {
  opacity: 1;
}

.categories .category:hover::before {
  opacity: .5;
}

.categories .category::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0;
  will-change: opacity;
  transition: .5s ease-in-out;
}

.categories .category.dummy {
  display: flex;
  align-items: center;
  pointer-events: none;
  background-color: #eeeeee;
}
.categories .category.dummy::before {
  border-right: 1px solid #dddddd;
}

.categories .category.dummy:last-child:before {
  border-right: none;;
}

.categories .category.dummy .loader-circle,
.categories .category.active .sub-categories .loader-circle {
  transform: scale(.5);
}

.categories .category:hover {
  flex-grow: 1.5;
}

.categories .category:hover .title {
  opacity: .65;
}

.categories .category .clicking-mouse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 15px;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transition: 150ms ease-in-out;
}

.categories .category:hover > .clicking-mouse {
  opacity: 1;
}

.categories .category .clicking-mouse .clicking-mouse__line-horizontal {
  position: absolute;
  top: 13px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
}

.categories .category .clicking-mouse .clicking-mouse__line-vertical {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;;
  height: 15px;
  background: #fff;
}

.categories .category .clicking-mouse::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 15px;
  background-color: #fff;
  border-top-left-radius: 15px;
  animation: clicking-mouse-animation 2s linear infinite;
}

@keyframes clicking-mouse-animation {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.categories .category .image-wrapper {
  overflow: hidden;
  position: relative;
  z-index: -1;
  height: 100%;
  display: block;
  width: 100%;
}

.categories .category img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: .5s ease-in-out;
}

.categories .category img.blur {
  filter: blur(15px);
  transform: scale(1.15);
}

.categories .category .category-index {
  position: absolute;
  top: -65px;
  left: 50%;
  transform: translate(-50%);
  color: #ffffff;
  font-family: "Montserrat";
  font-size: 35px;
  font-weight: 300;
  transition: .25s ease-in-out;
}

.categories .category.active .category-index.main {
  opacity: 0;
}

.categories .category .title,
.categories .category .sub-categories .sub-category .sub-category__name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $dark;
  margin: 0;
  height: 90px;
  padding: 20px;
  pointer-events: none;
  will-change: opacity;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .5s ease-in-out;
}

.categories .category .title h4,
.categories .category .sub-categories .sub-category .sub-category__name h4 {
  font-size: 16px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  width: 180px;
}

.categories .category:nth-child(even) .title,
.categories .category .sub-categories .sub-category:nth-child(even) .sub-category__name {
  background-color: #081920;
}

.categories .category.active .close-btn {
  opacity: 1;
  pointer-events: initial;
}
.categories .category .close-btn {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: white;
  background-color: $accent-dark;
  padding: 3px;
  padding-top: 0;
  transition: .15s ease-in-out;
  box-shadow: 0 0 15px rgba(0,0,0,0.15);
}

.categories .category .close-btn:hover {
  background-color: $accent;
}

.categories .category .close-btn:active {
  transform: translate(-50%) scale(.9);
}

.categories .category .sub-categories {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
  transition: .5s ease-in-out;
}

.categories .category .sub-categories.disabled {
  pointer-events: none !important;
}

.categories .category.active .sub-categories {
  opacity: 1;
  pointer-events: initial;
}

.categories .category .sub-categories .sub-category {
  position: relative;
  display: block;
  flex-grow: 1;
  flex-basis: 0;
  font-weight: 700;
  text-decoration: none;
  color: $font-color;
  text-align: center;
  line-height: 18px;
  will-change: opacity;
  transition: .5s ease-in-out;
}

.categories .category .sub-categories .sub-category::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0;
  will-change: opacity;
  transition: .5s ease-in-out;
}

.categories .category .sub-categories .sub-category:hover {
  flex-grow: 1.5;
}

.categories .category .sub-categories .sub-category:hover > .clicking-mouse {
  opacity: 1;
}

.categories .category .sub-categories .sub-category:hover .sub-category__name {
  opacity: .65;
}

.categories .category .sub-categories .sub-category:hover::before {
  opacity: .5;
}


/* SECTION TITLE =============================== */
.section-title-wrapper {
  position: absolute;
  bottom: 80px;
  left: 0;
  z-index: -1;
  height: 100px;
  display: flex;
  align-items: center;
  transform-origin: 0 0;
  transform: rotate(-90deg);
}

.section-title-wrapper .bar {
  width: 30px;
  height: 2px;
  margin-right: 15px;
  background-color: white;
  display: flex;
  align-items: center;
}
.section-title-wrapper .bar i {
  display: none;
  color: white;
}
.section-title-wrapper .section-title {
  font-weight: 600;
  color: white;
}


@media (max-width: 1500px) {
  .categories .category .title,
  .categories .category .sub-categories .sub-category .sub-category__name {
    height: 60px;
  }
  .categories .category .title h4,
  .categories .category .sub-categories .sub-category .sub-category__name h4 {
    font-size: 14px;
    width: 150px;
  }
  .categories .category .title .category-index,
  .categories .category .sub-categories .sub-category .sub-category__name .category-index {
    font-size: 25px;
  }
  .categories-wrapper .categories-wrapper-bg {
    height: calc(100% - 120px);
  }
  .section-title-wrapper {
    bottom: 50px;
  }
}

@media (max-width: 1200px) {
  .categories .category .title h4,
  .categories .category .sub-categories .sub-category .sub-category__name h4 {
    font-size: 12px;
    width: 130px;
  }
  .categories .category .title .category-index,
  .categories .category .sub-categories .sub-category .sub-category__name .category-index {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .categories {
    padding: 100px 0 60px 0;
  }
  .categories .category {
    flex-basis: auto;
    flex-grow: initial;
    flex-shrink: 0;
  }
  .categories .category:hover {
    flex-grow: initial;
  }
  .categories .category.dummy {
    margin-right: 5px;
    width: 300px;
  }
  .categories .category.dummy:first-child {
    margin-left: 150px;
  }
  .categories .category.active .title {
    opacity: 0;
  }
  .categories .category.not-active .title {
    opacity: 1 !important;
  }
  .categories .category.not-active::before {
    opacity: .25;
  }
  .categories .category::after {
    display: none;
  }
  .categories .category.active:hover::before,
  .categories .category.active::before {
    opacity: .75;
  }
  .categories .category.not-active .title {
    opacity: 1;
  }
  .categories .category .title .category-index,
  .categories .category .sub-categories .sub-category .sub-category__name .category-index {
    font-size: 25px;
  }
  .categories .category .sub-categories {
    flex-direction: column;
    justify-content: center;
  }
  .categories .category .sub-categories .sub-category {
    flex-grow: initial;
    flex-basis: auto;
    margin: 1px 0;
    height: 60px;
    transition: .1s ease-in-out;
  }
  .categories .category .sub-categories .sub-category::before {
    opacity: 0;
  }
  .categories .category .sub-categories .sub-category:hover {
    flex-grow: initial;
  }
  .categories .category .sub-categories .sub-category .sub-category__name .category-index {
    display: none;
  }
  .categories .category .sub-categories .sub-category img {
    display: none;
  }
  .categories .category .clicking-mouse {
    display: none;
  }
}

@media (max-width: 768px) {
  .categories {
    padding: 120px 0 60px 0;
  }
  .categories-wrapper .categories-wrapper-bg {
    width: 100%;
    height: calc(100% - 120px);
  }
  .categories .category {
    height: 400px;
  }
  .categories .category.dummy:first-child {
    margin-left: 50px;
  }
  .section-title-wrapper {
    transform: none;
    bottom: auto;
    top: 40px;
    left: auto;
    right: 0;

  }
  .section-title-wrapper .bar {
    order: 2;
    margin-left: 15px;
    margin-right: 20px;
    position: relative;
    height: auto;
    width: auto;
    background-color: transparent;
  }
  .section-title-wrapper .bar i {
    display: block;
  }
  .section-title-wrapper .section-title {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .categories .category {
    width: 100%;
  }
}
