@import '../../styles/colors.scss';

/* All posts */
.posts {
  margin-bottom: 25px;
}

@media(min-width: 992px) {
  .posts {
    margin-top: 25px;
  }
}

/* Single post */
.post {
  max-width: 740px;
  padding: 20px;
  margin: auto;
  margin-bottom: 50px;
}

.post h1, h2, h3, h4, h5 {
  font-weight: 600;
}

.post h4 {
  margin-top: 50px;
}

.post .post-share-buttons {
  position: absolute;
  position: sticky;
  top: 100px;
  left: 0;
  display: block;
  opacity: 1;
  margin-left: -120px;
  height: 0;
}

.post .post-share-buttons button,
.post .post-share-buttons .SocialMediaShareButton {
  width: 45px;
  height: 45px;
  margin: 0 0 10px 0;
  border: none;
  background-color: $accent;
  box-shadow: 0 0 10px -1px $accent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  transition: .15s ease-in-out;
}

.post .post-share-buttons button:hover,
.post .post-share-buttons .SocialMediaShareButton:hover {
  background-color: $accent-dark;
  box-shadow: 0 0 10px 0px $accent-dark;
}

.post .post-share-buttons .button:hover i,
.post .post-share-buttons .SocialMediaShareButton:hover img {
  opacity: 1;
}

.post .post-share-buttons button:active,
.post .post-share-buttons .SocialMediaShareButton:active {
  background-color: $accent-dark;
}

.post .post-share-buttons button a,
.post .post-share-buttons button i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%;
}

.post .post-share-buttons button i {
  color: white;
  will-change: opacity;
  transition: .15s ease-in-out;
}

.post .post-share-buttons .SocialMediaShareButton img {
  width: 50%;
  height: 50%;
  filter: invert(1);
  will-change: opacity;
  transition: .15s ease-in-out;
}

.post img {
  max-width: 100%;
  height: auto;
}
.post blockquote {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 40px;
  -webkit-margin-end: 40px;
}
.post .alignleft, img.alignleft {
  margin: 0.5em 1em 0.5em 0;
	display: inline;
	float: left;
}
.post .alignright, img.alignright {
  margin: 0.5em 0 0.5em 1em;
	display: inline;
	float: right;
}
.post .aligncenter, img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
	clear: both;
}

@media(max-width: 576px) {
  .post .alignleft, .post img.alignleft, .post .alignright, .post img.alignright {
    margin: 0.5em auto;
    display: block;
    float: none;
  }
  .post img.alignleft, img.alignright {
    object-fit: contain;
  }
}


/* Table */
.post table {
  overflow: hidden;
  border: none;
  box-shadow: 0 0 50px rgba(0,0,0,0.2);
  font-family: 'Montserrat';
  font-weight: 300;
  margin-bottom: 1rem;
  position: relative;
}
.post table thead td {
  border: none !important;
}
.post table td {
  border: 1px solid #f0f0f0 !important;
}
.post table td strong {
  font-weight: 600;
}
.post table tr:hover {
  background-color: #f2f2f2;
}
.post table td, .post table th {
  position: relative;
  border:none;
}
.post table td:hover::after,
.post table th:hover::after {
  content: '';
  background-color: #f2f2f2;
  position: absolute;
  left: 0;
  height: 10000px;
  top: -5000px;
  width: 100%;
  z-index: -1;
}
.post table td:hover {
  background-color: #5badff;
  color: #ffffff;
}
.post table td:hover a {
  color: #ffffff;
}
.post table thead th:hover,
.post table thead td:hover {
  background-color: rgba(54, 48, 74, 0.85);
  color: #ffffff;
}

.post table td, .post table tr {
  height: auto !important;
}

.post table td, .post table th, .post table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.post table thead td, .post table th {
  color: #fff;
  background-color: rgba(54, 48, 74, 0.7);
  font-weight: 600;
}


/* Post item */
.post-item--small {
  display: flex;
  width: calc(100% / 3);
  color: #000000;
  padding: 10px;
  position: relative;
}
.post-item--small.lg {
  width: calc(100% / 2);
}
.post-item--small.lg .image {
  height: 250px;
}
.post-item--small a {
  position: relative;
  text-decoration: none;
}
.post-item--small:hover .image {
  opacity: .75;
}
.post-item--small a {
  width: 100%;
}
.post-item--small .image {
  width: 100%;
  height: 175px;
  background-color: #eeeeee;
  transition: .1s ease-in-out;
}
.post-item--small .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post-item--small .data {
  padding-bottom: 30px;
}
.post-item--small .data h1 {
  font-weight: 700;
  font-size: 20px;
  color: #000000;
  padding: 10px 0;
  margin: 0;
}
.post-item--small .data .first-paragraph {
  font-size: 17px;
  color: #786c5d;
}
.post-item--small .data .date {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #000000;
}

.post-share-buttons {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
  opacity: 0;
  display: flex;
  will-change: opacity;
  transition: .2s ease-in-out;
}

.post-item--small:hover .post-share-buttons {
  opacity: 1;
}

.post-share-buttons .SocialMediaShareButton {
  width: 35px;
  height: 35px;
  margin: 2.5px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  transition: .15s ease-in-out;
}
.post-share-buttons .SocialMediaShareButton:hover {
  border-color: rgba(255, 255, 255, 0.5);
}
.post-share-buttons .SocialMediaShareButton:hover img {
  opacity: .5;
}

.post-share-buttons .SocialMediaShareButton:active {
  transform: scale(.95);
  background-color: #ffffff;
}

.post-share-buttons .SocialMediaShareButton:active img {
  filter: none;
}

.post-share-buttons .SocialMediaShareButton img {
  width: 50%;
  height: 50%;
  filter: invert(1);
  will-change: opacity;
  transition: .15s ease-in-out;
}

@media(max-width: 1200px) {
  .post-item--small {
    width: calc(100% / 3);
  }
}
@media(max-width: 850px) {
  .post-item--small {
    width: calc(100% / 2);
  }
  .post-item--small.lg .image {
    height: 175px;
  }
}
@media(max-width: 576px) {
  .posts {
    padding: 0;
  }
  .post-item--small, .post-item--small.lg {
    width: calc(100% / 1);
    padding: 0;
  }
  .post-item--small .data {
    padding: 0 14px;
  }
  .post-item--small .data .date {
    position: relative;
    margin: 1rem 0;
  }
  .post-item--small .image, .post-item--small.lg .image {
    height: 200px;
  }
}