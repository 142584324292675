@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600,800);
@import url(https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:400,500,600);
body {
  font-family: 'Raleway'; }

body.disable-scroll {
  overflow: hidden; }

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.content {
  flex-grow: 1; }

h1 {
  font-size: 32px;
  margin-bottom: 25px; }

@media (max-width: 576px) {
  h1 {
    font-size: 27px; } }

.mw-1200 {
  max-width: 1140px; }

.mw-1000 {
  max-width: 1000px; }

section {
  padding: 50px 0; }

/* Scrollbar */
*::-webkit-scrollbar {
  width: 7.5px; }

*::-webkit-scrollbar-track {
  background-color: #ffffff; }

*::-webkit-scrollbar-thumb {
  background-color: #202c35; }

*::-webkit-scrollbar-thumb:hover {
  background-color: #546475; }

button {
  outline: none !important; }

.my-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  padding-left: 17.5px;
  padding-right: 17.5px;
  height: 37.5px;
  margin: 10px 0;
  border: none;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  background-color: #2664e3;
  text-decoration: none;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out; }

.my-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-transition: .15s ease-out;
  transition: .15s ease-out; }

.my-btn:hover {
  text-decoration: none;
  color: #ffffff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25); }

.my-btn:hover::before {
  opacity: 1; }

.my-btn:active {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
  box-shadow: none; }

.my-btn.disabled {
  background-color: #d4d4d4;
  color: #525761;
  pointer-events: none; }

.my-btn--light {
  background-color: transparent;
  color: #202c35;
  font-weight: 500;
  box-shadow: none;
  background-color: transparent;
  border: 2px solid #202c35; }

.my-btn--light::before {
  background-color: rgba(0, 0, 0, 0.05); }

.my-btn--light:hover {
  color: #000000;
  box-shadow: none; }

.my-btn--light:active {
  background-color: #ffffff;
  color: #000000;
  box-shadow: none; }

.my-btn--white {
  background-color: transparent;
  color: white;
  font-weight: 500;
  box-shadow: none;
  background-color: transparent;
  border: 2px solid white; }

.my-btn--white::before {
  background-color: rgba(255, 255, 255, 0.05); }

.my-btn--white:hover {
  color: white;
  box-shadow: none; }

.my-btn--white:active {
  background-color: #ffffff;
  color: #000000;
  box-shadow: none; }

.header-sep {
  background-color: #2664e3;
  width: 30px;
  height: 2px;
  margin: 10px auto 30px auto; }

/* Form */
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center; }

.form-control {
  background-color: #fafafa;
  border-color: #e9e9e9;
  border-radius: 20px; }

/* ANIMATION CLASSES ============================================================== */
/* FADE-IN ========================= */
.fade-in {
  -webkit-animation: fade-in 250ms ease-in;
          animation: fade-in 250ms ease-in; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* GROW-IN BOTTOM ========================= */
.grow-in-bottom {
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-animation: grow-in-bottom-animation ease-in-out;
          animation: grow-in-bottom-animation ease-in-out; }

@-webkit-keyframes grow-in-bottom-animation {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes grow-in-bottom-animation {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

/* GROW-IN LEFT ========================= */
.grow-in-left {
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
  -webkit-animation: grow-in-left-animation ease-in-out;
          animation: grow-in-left-animation ease-in-out; }

@-webkit-keyframes grow-in-left-animation {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes grow-in-left-animation {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

/* GROW-IN RIGHT ========================= */
.grow-in-right {
  -webkit-transform-origin: 100% center;
          transform-origin: 100% center;
  -webkit-animation: grow-in-right-animation ease-in-out;
          animation: grow-in-right-animation ease-in-out; }

@-webkit-keyframes grow-in-right-animation {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes grow-in-right-animation {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

/* SLIDE-IN LEFT ========================= */
.slide-in-left {
  -webkit-transform-origin: 100% center;
          transform-origin: 100% center;
  -webkit-animation: slide-in-left-animation ease-in-out;
          animation: slide-in-left-animation ease-in-out; }

@-webkit-keyframes slide-in-left-animation {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slide-in-left-animation {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

/* SLIDE-IN RIGHT ========================= */
.slide-in-right {
  -webkit-transform-origin: 100% center;
          transform-origin: 100% center;
  -webkit-animation: slide-in-right-animation ease-in-out;
          animation: slide-in-right-animation ease-in-out; }

@-webkit-keyframes slide-in-right-animation {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slide-in-right-animation {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

/* SLIDE-IN BOTTOM ========================= */
.slide-in-bottom {
  -webkit-transform-origin: 100% center;
          transform-origin: 100% center;
  -webkit-animation: slide-in-bottom-animation ease-in-out;
          animation: slide-in-bottom-animation ease-in-out; }

@-webkit-keyframes slide-in-bottom-animation {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes slide-in-bottom-animation {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

/* SLIDE-IN TOP ========================= */
.slide-in-top {
  -webkit-transform-origin: 100% center;
          transform-origin: 100% center;
  -webkit-animation: slide-in-top-animation ease-in-out;
          animation: slide-in-top-animation ease-in-out; }

@-webkit-keyframes slide-in-top-animation {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes slide-in-top-animation {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.material-icons {
  /* Support for IE */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga'; }

header {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 998;
  border-bottom: 1px solid transparent;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

header.header--small {
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(0, 0, 0, 0.1); }

header.header--small nav .nav__item--logo::before {
  border-top-color: #2664e3; }

header.header--small nav .nav__item--logo .nav__item--logo--dark {
  opacity: 0; }

header.header--small nav .nav__item--logo::after {
  display: block; }

header.header--small nav .nav__item--search,
header.header--small nav .nav__item--mobile {
  color: #202c35; }

nav {
  display: flex;
  align-items: center;
  height: 60px;
  margin-right: 150px;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

nav .nav__item {
  color: #000000;
  padding: 10px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  position: relative;
  font-weight: 600;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

nav .nav__item--hover:hover::after {
  -webkit-transform: translate(-50%) scaleX(1);
          transform: translate(-50%) scaleX(1); }

nav .nav__item--hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 50%;
  z-index: -1;
  background-color: #3d7eff;
  -webkit-transform-origin: 50%;
          transform-origin: 50%;
  will-change: transform;
  -webkit-transform: translate(-50%) scaleX(0);
          transform: translate(-50%) scaleX(0);
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

nav .nav__item--logo {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 55px;
  margin: 0 auto 0 -35px;
  width: auto;
  position: relative; }

nav .nav__item--logo:hover img {
  opacity: .5; }

nav .nav__item--logo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-top: 60px solid white;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  height: 0;
  width: 100%;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

nav .nav__item--logo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  border-top: 60px solid rgba(0, 0, 0, 0.25);
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  height: 0;
  width: 100%;
  -webkit-filter: blur(10px);
          filter: blur(10px);
  will-change: border-top;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

nav .nav__item--logo img {
  width: 110px;
  will-change: opacity;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

nav .nav__item--logo .nav__item--logo--dark {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: 150ms ease-in-out;
  transition: 150ms ease-in-out; }

nav .nav__item--mobile {
  cursor: pointer;
  width: auto;
  color: white; }

nav .nav__item--mobile:hover i {
  opacity: .5; }

nav .nav__item--mobile i {
  font-size: 25px;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

nav .nav__item--search {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

nav .nav__item--search:hover i {
  background-color: rgba(0, 0, 0, 0.1); }

nav .nav__item--search i {
  font-size: 30px;
  border-radius: 50%;
  padding: 9px;
  -webkit-transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1); }

@media (min-width: 993px) {
  nav .nav__item--mobile {
    display: none; }
  nav .nav__item--search {
    color: #202c35; } }

@media (max-width: 992px) {
  header nav {
    margin-right: 65px; }
  nav .nav__item {
    order: 1; }
  nav .nav__item--logo {
    order: 0;
    margin: 0 auto 0 -35px; }
  nav .nav__item--logo::after {
    display: none; }
  nav .nav__item--search {
    width: auto; } }

@media (max-width: 992px) and (min-width: 769px) {
  nav .nav__item--search,
  nav .nav__item--mobile {
    color: #202c35; } }

@media (max-width: 992px) {
  header.header--small nav {
    margin-right: 0; }
  header nav {
    margin-right: 0; }
  nav .nav__item--search {
    margin-right: 50px; }
  nav .nav__item--hover {
    display: none; }
  header.header--small nav .nav__item--search,
  header.header--small nav .nav__item--mobile {
    color: #202c35; } }

@media (max-width: 480px) {
  header.header--small nav {
    background-color: #2664e3;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25); }
  header.header--small nav .nav__item--mobile::after {
    box-shadow: none; }
  header.header--small nav .nav__item--logo::after {
    display: none; }
  header.header--small nav .nav__item--mobile {
    color: #ffffff; }
  header.header--small nav .nav__item--search i {
    color: #ffffff; } }

.sidenav {
  position: fixed;
  height: 100%;
  width: 350px;
  max-width: 100%;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  z-index: 999;
  top: 0;
  right: 0;
  background-color: #2f3c4a;
  -webkit-transition: 0.5s;
  transition: 0.5s; }

.sidenav::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  box-shadow: 0 0 200px rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.5s;
  transition: 0.5s; }

.sidenav.visible {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.sidenav.visible::before {
  opacity: 1; }

.sidenav .top-wrapper {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 10px; }

.sidenav .top-wrapper.big {
  padding: 12px 10px; }

.sidenav .top-wrapper.big .col {
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.sidenav .top-wrapper.big .col .username {
  margin: 0;
  color: #000; }

.sidenav .top-wrapper .top-menu-item {
  justify-content: center;
  background-color: #eee;
  margin-right: 10px;
  padding: 6px 18px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500; }

.sidenav .top-wrapper .top-menu-item:hover {
  background-color: #f5f5f5; }

.sidenav a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  padding: 18px 0;
  white-space: nowrap;
  background-color: #546475;
  border-top: 1px solid #3d4c5c;
  -webkit-transition: 0.05s ease-in-out;
  transition: 0.05s ease-in-out; }

.sidenav a:hover {
  background-color: #4d5c6b; }

.sidenav a i {
  width: 20px !important;
  font-size: 20px;
  margin: 0 30px 0 20px;
  opacity: 0.25;
  -webkit-transition: 0.05s ease-in-out;
  transition: 0.05s ease-in-out; }

.sidenav a i.arrow {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  opacity: 0.15; }

.sidenav a:hover i.arrow {
  opacity: 0.5; }

.sidenav hr {
  background-color: transparent;
  margin: 3px; }

.sidenav .close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  font-size: 50px;
  font-weight: 300;
  padding-bottom: 5px;
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out; }

.sidenav .close-btn:hover {
  opacity: 0.5;
  background-color: rgba(255, 255, 255, 0.25); }

.searchbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 60px;
  background-color: #546475;
  display: flex;
  box-shadow: 0 0 75px rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  will-change: opacity;
  -webkit-transition: visibility 0s linear 250ms, all 250ms ease-in-out;
  transition: visibility 0s linear 250ms, all 250ms ease-in-out; }

.searchbar-wrapper.visible {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 0s;
          transition-delay: 0s; }

.searchbar-wrapper .close-btn,
.searchbar-wrapper .search-icon {
  background-color: #2f3c4a;
  color: #ffffff;
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  will-change: backround-color;
  -webkit-transition: 150ms ease-in-out;
  transition: 150ms ease-in-out; }

.searchbar-wrapper .close-btn:hover,
.searchbar-wrapper .search-icon:hover {
  background-color: #445261; }

.searchbar-wrapper .close-btn i,
.searchbar-wrapper .search-icon i {
  -webkit-transition: 300ms ease-in-out 50ms;
  transition: 300ms ease-in-out 50ms; }

.searchbar-wrapper .search-icon {
  background-color: #2664e3; }

.searchbar-wrapper .search-icon:hover {
  background-color: #3d7eff; }

.searchbar-wrapper.visible .close-btn i {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.searchbar-wrapper .search-input {
  flex-grow: 1;
  position: relative; }

.searchbar-wrapper .search-input .placeholder {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 25px;
  font-size: 20px;
  color: #ffffff;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  opacity: .75;
  -webkit-transition: all 0.15s ease-in-out, -webkit-transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1) 150ms;
  transition: all 0.15s ease-in-out, -webkit-transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1) 150ms;
  transition: transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1) 150ms, all 0.15s ease-in-out;
  transition: transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1) 150ms, all 0.15s ease-in-out, -webkit-transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1) 150ms; }

.searchbar-wrapper.visible .search-input .placeholder {
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.searchbar-wrapper .search-input:hover .placeholder {
  opacity: .5; }

.searchbar-wrapper .search-input .placeholder.hide {
  opacity: 0; }

.searchbar-wrapper .search-input form {
  width: 100%;
  height: 100%; }

.searchbar-wrapper .search-input input {
  width: 100%;
  height: 100%;
  padding: 0 25px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #ffffff;
  font-size: 20px; }

@media (max-width: 768px) {
  .searchbar-wrapper .search-icon {
    display: none; } }

footer {
  background-color: #202c35;
  padding: 50px 0;
  position: relative; }

footer .logo {
  width: 110px;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

footer .logo:hover {
  opacity: .5; }

footer .rights {
  color: #747e88;
  font-size: 15px; }

footer .rights .avalon {
  width: 38px;
  height: 38px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin-left: 15px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 0 1px transparent;
  opacity: .5;
  will-change: opacity;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

footer .rights .avalon img {
  width: 100%;
  height: 100%;
  -webkit-filter: invert(1);
          filter: invert(1); }

footer .rights .avalon:hover {
  opacity: 1; }

footer .contact-item {
  display: flex;
  align-items: center;
  margin: 15px 0;
  text-decoration: none; }

footer .contact-item:hover p {
  opacity: .5; }

footer .contact-item p {
  margin: 0;
  white-space: nowrap;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  will-change: opacity;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

footer .contact-item img,
footer .contact-item .icon-placeholder {
  margin-right: 20px;
  width: 20px;
  -webkit-filter: invert(1);
          filter: invert(1); }

footer .to-up-btn {
  position: absolute;
  top: -25px;
  right: 100px;
  z-index: 10;
  background-color: #2664e3;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  will-change: background-color;
  -webkit-transition: all .15s ease-in-out, -webkit-transform .35s ease-in-out;
  transition: all .15s ease-in-out, -webkit-transform .35s ease-in-out;
  transition: transform .35s ease-in-out, all .15s ease-in-out;
  transition: transform .35s ease-in-out, all .15s ease-in-out, -webkit-transform .35s ease-in-out; }

footer .to-up-btn:hover {
  background-color: #1b57ce;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px); }

footer .to-up-btn i {
  font-size: 25px;
  color: #ffffff; }

@media (max-width: 768px) {
  footer .to-up-btn {
    right: 25px; } }

.home {
  position: relative; }

.home .lines {
  position: absolute;
  top: 0;
  left: 150px;
  z-index: -1;
  width: calc(100% - 300px);
  height: 100%; }

.home .lines .line {
  position: absolute;
  top: -100px;
  width: 1px;
  height: 110%;
  background-color: rgba(0, 0, 0, 0.075); }

.home .lines .line.line-1 {
  left: 0; }

.home .lines .line.line-2 {
  left: 20%; }

.home .lines .line.line-3 {
  left: 40%; }

.home .lines .line.line-4 {
  right: 40%; }

.home .lines .line.line-5 {
  right: 20%; }

.home .lines .line.line-6 {
  right: 0; }

@media (max-width: 992px) {
  .home .lines {
    display: none; } }

.categories-wrapper {
  position: relative; }

.categories-wrapper .categories-wrapper-bg {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 50%;
  height: calc(100% - 150px);
  background-color: #2664e3; }

.categories {
  display: flex;
  margin-top: -60px;
  padding: 100px 150px 60px 150px;
  position: relative;
  overflow: hidden; }

.categories .swiper-container {
  width: 100%; }

.categories .swiper-wrapper {
  display: flex; }

.categories .category {
  flex-grow: 1;
  flex-basis: 0;
  height: 65vh;
  position: relative;
  z-index: 1;
  background-color: #eeeeee;
  cursor: pointer;
  will-change: width;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out; }

.categories .category::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), to(transparent));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5), transparent);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), transparent); }

.categories .category.active {
  flex-grow: 8; }

.categories .category.active:hover {
  flex-grow: 8; }

.categories .category.not-active .title {
  opacity: 0 !important; }

.categories .category.not-active::before {
  opacity: .9; }

.categories .category.active:hover::before,
.categories .category.active::before {
  opacity: 1; }

.categories .category:hover::before {
  opacity: .5; }

.categories .category::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out; }

.categories .category.dummy {
  display: flex;
  align-items: center;
  pointer-events: none;
  background-color: #eeeeee; }

.categories .category.dummy::before {
  border-right: 1px solid #dddddd; }

.categories .category.dummy:last-child:before {
  border-right: none; }

.categories .category.dummy .loader-circle,
.categories .category.active .sub-categories .loader-circle {
  -webkit-transform: scale(0.5);
          transform: scale(0.5); }

.categories .category:hover {
  flex-grow: 1.5; }

.categories .category:hover .title {
  opacity: .65; }

.categories .category .clicking-mouse {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 25px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 15px;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: 150ms ease-in-out;
  transition: 150ms ease-in-out; }

.categories .category:hover > .clicking-mouse {
  opacity: 1; }

.categories .category .clicking-mouse .clicking-mouse__line-horizontal {
  position: absolute;
  top: 13px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff; }

.categories .category .clicking-mouse .clicking-mouse__line-vertical {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 2px;
  height: 15px;
  background: #fff; }

.categories .category .clicking-mouse::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 15px;
  background-color: #fff;
  border-top-left-radius: 15px;
  -webkit-animation: clicking-mouse-animation 2s linear infinite;
          animation: clicking-mouse-animation 2s linear infinite; }

@-webkit-keyframes clicking-mouse-animation {
  0% {
    opacity: 0; }
  45% {
    opacity: 0; }
  50% {
    opacity: 1; }
  70% {
    opacity: 1; }
  75% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes clicking-mouse-animation {
  0% {
    opacity: 0; }
  45% {
    opacity: 0; }
  50% {
    opacity: 1; }
  70% {
    opacity: 1; }
  75% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.categories .category .image-wrapper {
  overflow: hidden;
  position: relative;
  z-index: -1;
  height: 100%;
  display: block;
  width: 100%; }

.categories .category img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out; }

.categories .category img.blur {
  -webkit-filter: blur(15px);
          filter: blur(15px);
  -webkit-transform: scale(1.15);
          transform: scale(1.15); }

.categories .category .category-index {
  position: absolute;
  top: -65px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  color: #ffffff;
  font-family: "Montserrat";
  font-size: 35px;
  font-weight: 300;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

.categories .category.active .category-index.main {
  opacity: 0; }

.categories .category .title,
.categories .category .sub-categories .sub-category .sub-category__name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #202c35;
  margin: 0;
  height: 90px;
  padding: 20px;
  pointer-events: none;
  will-change: opacity;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out; }

.categories .category .title h4,
.categories .category .sub-categories .sub-category .sub-category__name h4 {
  font-size: 16px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  width: 180px; }

.categories .category:nth-child(even) .title,
.categories .category .sub-categories .sub-category:nth-child(even) .sub-category__name {
  background-color: #081920; }

.categories .category.active .close-btn {
  opacity: 1;
  pointer-events: initial; }

.categories .category .close-btn {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: -50px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  z-index: 1;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: white;
  background-color: #2664e3;
  padding: 3px;
  padding-top: 0;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); }

.categories .category .close-btn:hover {
  background-color: #3d7eff; }

.categories .category .close-btn:active {
  -webkit-transform: translate(-50%) scale(0.9);
          transform: translate(-50%) scale(0.9); }

.categories .category .sub-categories {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out; }

.categories .category .sub-categories.disabled {
  pointer-events: none !important; }

.categories .category.active .sub-categories {
  opacity: 1;
  pointer-events: initial; }

.categories .category .sub-categories .sub-category {
  position: relative;
  display: block;
  flex-grow: 1;
  flex-basis: 0;
  font-weight: 700;
  text-decoration: none;
  color: #5d5d5d;
  text-align: center;
  line-height: 18px;
  will-change: opacity;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out; }

.categories .category .sub-categories .sub-category::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out; }

.categories .category .sub-categories .sub-category:hover {
  flex-grow: 1.5; }

.categories .category .sub-categories .sub-category:hover > .clicking-mouse {
  opacity: 1; }

.categories .category .sub-categories .sub-category:hover .sub-category__name {
  opacity: .65; }

.categories .category .sub-categories .sub-category:hover::before {
  opacity: .5; }

/* SECTION TITLE =============================== */
.section-title-wrapper {
  position: absolute;
  bottom: 80px;
  left: 0;
  z-index: -1;
  height: 100px;
  display: flex;
  align-items: center;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.section-title-wrapper .bar {
  width: 30px;
  height: 2px;
  margin-right: 15px;
  background-color: white;
  display: flex;
  align-items: center; }

.section-title-wrapper .bar i {
  display: none;
  color: white; }

.section-title-wrapper .section-title {
  font-weight: 600;
  color: white; }

@media (max-width: 1500px) {
  .categories .category .title,
  .categories .category .sub-categories .sub-category .sub-category__name {
    height: 60px; }
  .categories .category .title h4,
  .categories .category .sub-categories .sub-category .sub-category__name h4 {
    font-size: 14px;
    width: 150px; }
  .categories .category .title .category-index,
  .categories .category .sub-categories .sub-category .sub-category__name .category-index {
    font-size: 25px; }
  .categories-wrapper .categories-wrapper-bg {
    height: calc(100% - 120px); }
  .section-title-wrapper {
    bottom: 50px; } }

@media (max-width: 1200px) {
  .categories .category .title h4,
  .categories .category .sub-categories .sub-category .sub-category__name h4 {
    font-size: 12px;
    width: 130px; }
  .categories .category .title .category-index,
  .categories .category .sub-categories .sub-category .sub-category__name .category-index {
    font-size: 20px; } }

@media (max-width: 992px) {
  .categories {
    padding: 100px 0 60px 0; }
  .categories .category {
    flex-basis: auto;
    flex-grow: initial;
    flex-shrink: 0; }
  .categories .category:hover {
    flex-grow: initial; }
  .categories .category.dummy {
    margin-right: 5px;
    width: 300px; }
  .categories .category.dummy:first-child {
    margin-left: 150px; }
  .categories .category.active .title {
    opacity: 0; }
  .categories .category.not-active .title {
    opacity: 1 !important; }
  .categories .category.not-active::before {
    opacity: .25; }
  .categories .category::after {
    display: none; }
  .categories .category.active:hover::before,
  .categories .category.active::before {
    opacity: .75; }
  .categories .category.not-active .title {
    opacity: 1; }
  .categories .category .title .category-index,
  .categories .category .sub-categories .sub-category .sub-category__name .category-index {
    font-size: 25px; }
  .categories .category .sub-categories {
    flex-direction: column;
    justify-content: center; }
  .categories .category .sub-categories .sub-category {
    flex-grow: initial;
    flex-basis: auto;
    margin: 1px 0;
    height: 60px;
    -webkit-transition: .1s ease-in-out;
    transition: .1s ease-in-out; }
  .categories .category .sub-categories .sub-category::before {
    opacity: 0; }
  .categories .category .sub-categories .sub-category:hover {
    flex-grow: initial; }
  .categories .category .sub-categories .sub-category .sub-category__name .category-index {
    display: none; }
  .categories .category .sub-categories .sub-category img {
    display: none; }
  .categories .category .clicking-mouse {
    display: none; } }

@media (max-width: 768px) {
  .categories {
    padding: 120px 0 60px 0; }
  .categories-wrapper .categories-wrapper-bg {
    width: 100%;
    height: calc(100% - 120px); }
  .categories .category {
    height: 400px; }
  .categories .category.dummy:first-child {
    margin-left: 50px; }
  .section-title-wrapper {
    -webkit-transform: none;
            transform: none;
    bottom: auto;
    top: 40px;
    left: auto;
    right: 0; }
  .section-title-wrapper .bar {
    order: 2;
    margin-left: 15px;
    margin-right: 20px;
    position: relative;
    height: auto;
    width: auto;
    background-color: transparent; }
  .section-title-wrapper .bar i {
    display: block; }
  .section-title-wrapper .section-title {
    font-size: 14px; } }

@media (max-width: 576px) {
  .categories .category {
    width: 100%; } }

.loader-circle {
  position: relative;
  width: 70px;
  height: 70px;
  margin: auto; }

.loader-circle:before {
  content: '';
  display: block;
  padding-top: 100%; }

.loader-circle .circular {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

.loader-circle .circular .path {
  stroke: #2664e3;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round; }

.loader-circle.dark .circular .path {
  -webkit-animation: dash 1.5s ease-in-out infinite, colorDark 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, colorDark 6s ease-in-out infinite; }

.loader-circle.light .circular .path {
  -webkit-animation: dash 1.5s ease-in-out infinite, colorLight 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, colorLight 6s ease-in-out infinite; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@-webkit-keyframes color {
  100%,
  0% {
    stroke: #2664e3; }
  40% {
    stroke: #3d7eff; }
  66% {
    stroke: #2664e3; }
  80%,
  90% {
    stroke: #3d7eff; } }

@keyframes color {
  100%,
  0% {
    stroke: #2664e3; }
  40% {
    stroke: #3d7eff; }
  66% {
    stroke: #2664e3; }
  80%,
  90% {
    stroke: #3d7eff; } }

@-webkit-keyframes colorDark {
  0% {
    stroke: #000000; }
  100% {
    stroke: #000000; } }

@keyframes colorDark {
  0% {
    stroke: #000000; }
  100% {
    stroke: #000000; } }

@-webkit-keyframes colorLight {
  0% {
    stroke: #ffffff; }
  100% {
    stroke: #ffffff; } }

@keyframes colorLight {
  0% {
    stroke: #ffffff; }
  100% {
    stroke: #ffffff; } }

.news-wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative; }

section.news .section__name {
  padding-bottom: 15px; }

section.news .new {
  display: block;
  padding-bottom: 15px;
  color: #5d5d5d;
  text-decoration: none; }

section.news .new:hover .new__title::before {
  -webkit-transform: scaleX(3);
          transform: scaleX(3); }

section.news .new .new__title {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin: 3px 0;
  position: relative; }

section.news .new .new__title .number {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  padding: 0 10px;
  position: relative;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

section.news .new:hover .new__title .number {
  color: white; }

section.news .new:hover .new__title .number::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1); }

section.news .new .new__title .number::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #2664e3;
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

section.news .new .new__title .bar {
  width: 2px;
  margin-right: 10px;
  background-color: #2664e3;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

section.news .new .new__excerpt {
  font-weight: 300; }

section.news .images {
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

section.news .images .new__image {
  position: absolute;
  top: 50%;
  left: 40%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 500px;
  height: 350px;
  object-fit: cover;
  background-color: #eee;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  -webkit-transition: .5s ease-in-out, opacity .15s ease-in-out .35s;
  transition: .5s ease-in-out, opacity .15s ease-in-out .35s; }

section.news .images .new__image.fade-out {
  opacity: 0; }

section.news .images .new__image.new__image--first {
  -webkit-transform: translate3d(-60%, -40%, 1px);
          transform: translate3d(-60%, -40%, 1px); }

section.news .images .new__image.new__image--last {
  -webkit-transform: translate3d(-40%, -60%, -1px);
          transform: translate3d(-40%, -60%, -1px);
  -webkit-animation: gotoLast .5s ease-in-out forwards;
          animation: gotoLast .5s ease-in-out forwards; }

@media (max-width: 1400px) {
  section.news .images .new__image {
    width: 400px;
    height: 275px;
    left: 50%; } }

@media (max-width: 1200px) {
  .news-wrapper {
    padding-bottom: 50px; }
  .news-wrapper .box.box-1 {
    top: 50%; }
  section.news .images .new__image {
    width: 300px;
    height: 206.25px; } }

@media (max-width: 992px) {
  .news-wrapper {
    padding-top: 0; }
  .news-wrapper .lines {
    display: none; } }

@media (max-width: 480px) {
  section.news .new .new__title {
    font-size: 18px; }
  section.news .new .new__excerpt {
    font-size: 15px;
    text-align: justify; } }

@-webkit-keyframes gotoLast {
  50% {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes gotoLast {
  50% {
    opacity: 0; }
  to {
    opacity: 1; } }

.scroll-indicator-wrapper {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: calc(50% - 30px);
  z-index: 1;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

.scroll-indicator-wrapper:hover .scroll-indicator {
  border-color: #3d7eff; }

.scroll-indicator-wrapper:hover .scroll-indicator .scroll-wheel {
  background-color: #3d7eff; }

.scroll-indicator-wrapper:active {
  -webkit-transform: scale(0.97);
          transform: scale(0.97); }

.scroll-indicator {
  width: 25px;
  height: 40px;
  border: 2px solid #2664e3;
  border-radius: 15px;
  text-align: center; }

.scroll-indicator .scroll-wheel {
  height: 8px;
  margin: 2px auto 0;
  display: block;
  width: 3px;
  background-color: #2664e3;
  border-radius: 1.5px;
  will-change: opacity, transform;
  -webkit-animation: 1.6s ease infinite wheel-up-down;
          animation: 1.6s ease infinite wheel-up-down; }

@-webkit-keyframes wheel-up-down {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  70% {
    opacity: 1; }
  100% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    opacity: 0; } }

@keyframes wheel-up-down {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  70% {
    opacity: 1; }
  100% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    opacity: 0; } }

@media (max-width: 992px) {
  .scroll-indicator-wrapper {
    display: none; } }

section.partners {
  padding: 30px 0; }

section.partners .container {
  display: flex;
  justify-content: space-between; }

section.partners .rcs_buttonSet {
  display: none; }

section.partners .rcs_dotsSet {
  display: none; }

section.partners .itemWrapper {
  height: 50px;
  background: transparent; }

section.partners .itemWrapper img {
  max-width: 200px;
  object-fit: contain;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: .5;
  will-change: opacity, filter;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

section.partners .itemWrapper img:hover {
  opacity: 1;
  -webkit-filter: grayscale(0);
          filter: grayscale(0); }

section.partners .rcs_sliderBox {
  height: 100px !important;
  background: #ffffff !important;
  position: relative; }

section.partners .rcs_sliderBox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 50px;
  background: -webkit-gradient(linear, left top, right top, from(#ffffff), to(transparent));
  background: -webkit-linear-gradient(left, #ffffff, transparent);
  background: linear-gradient(to right, #ffffff, transparent); }

section.partners .rcs_sliderBox::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  width: 50px;
  background: -webkit-gradient(linear, right top, left top, from(#ffffff), to(transparent));
  background: -webkit-linear-gradient(right, #ffffff, transparent);
  background: linear-gradient(to left, #ffffff, transparent); }

.reviews {
  padding: 75px 0 100px 0;
  position: relative;
  overflow: hidden; }

.reviews .reviews-wrapper {
  padding: 0 150px;
  position: relative;
  z-index: 1; }

.reviews .background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  background-color: #fafafa; }

.reviews .noise {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #fafafa; }

.reviews .particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px 0;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out; }

.reviews .particles.disabled {
  opacity: 0;
  -webkit-transition: 0s;
  transition: 0s; }

.reviews .particles canvas {
  width: 100%;
  height: 100%; }

.reviews .arrow {
  position: absolute;
  bottom: -50px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  font-weight: 100;
  width: 50px;
  height: 50px;
  color: white;
  background-color: #2664e3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  will-change: opacity;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

.reviews .arrow.arrow--right {
  left: 50px;
  background-color: #3d7eff; }

.reviews .arrow.disabled {
  pointer-events: none; }

.reviews .arrow:hover {
  opacity: .75; }

.reviews .image-container,
.reviews .text-container {
  width: 30%; }

.reviews .text-container {
  position: relative;
  height: 270px;
  background-color: #202c35; }

.reviews .text {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 75px;
  text-align: center;
  color: #ffffff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-shadow: 0 0 75px rgba(0, 0, 0, 0.05); }

.reviews .text .title {
  font-style: italic;
  font-size: 14px; }

.reviews .text .title::before {
  content: "- "; }

.reviews .text .title::after {
  content: " -"; }

.review-index-container {
  position: absolute;
  bottom: -8px;
  right: 0;
  margin: 0; }

.review-index {
  position: relative;
  width: 30px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.2); }

.review-index::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2664e3;
  opacity: 0;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transition: 0.75s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: 0.75s cubic-bezier(0.68, -0.55, 0.27, 1.55); }

.review-index.active::before {
  opacity: 1;
  -webkit-transform: scaleX(1);
          transform: scaleX(1); }

.reviews .image-container {
  height: 320px;
  padding: 0; }

.reviews .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

@-webkit-keyframes review-index-active {
  0% {
    background-color: #d9d9d9; }
  100% {
    background-color: #2664e3; } }

@keyframes review-index-active {
  0% {
    background-color: #d9d9d9; }
  100% {
    background-color: #2664e3; } }

@media (max-width: 1600px) {
  .reviews .image-container,
  .reviews .text-container {
    width: 50%; } }

@media (max-width: 1100px) {
  .reviews .image-container,
  .reviews .text-container {
    width: 100%;
    height: 270px; } }

@media (max-width: 992px) {
  .reviews .text {
    padding: 25px 35px; } }

@media (max-width: 768px) {
  .reviews .image-container {
    height: 220px; }
  .reviews .reviews-wrapper {
    padding: 0 50px; } }

@media (max-width: 576px) {
  .reviews .reviews-wrapper {
    padding: 0 15px; }
  .reviews .arrow {
    font-size: 75px; }
  .reviews .text {
    margin: 0 15px;
    width: calc(100% - 30px); }
  .reviews .image-container {
    order: 1;
    height: 220px; }
  .reviews .text-container {
    order: 1;
    height: 320px; }
  .reviews .arrow--left {
    order: 2; }
  .reviews .arrow--right {
    order: 3; } }

/* Fade in classes */
/* to right */
.reviews .text.review-item-right-enter {
  opacity: 0.01;
  -webkit-transform: translate(-50px, -50%) scale(0.9);
          transform: translate(-50px, -50%) scale(0.9); }

.reviews .text.review-item-right-enter.review-item-right-enter-active {
  opacity: 1;
  -webkit-transform: translate(0, -50%) scale(1);
          transform: translate(0, -50%) scale(1);
  -webkit-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out; }

.reviews .text.review-item-right-leave {
  opacity: 1; }

.reviews .text.review-item-right-leave.review-item-right-leave-active {
  opacity: 0.01;
  -webkit-transform: translate(50px, -50%) scale(0.9);
          transform: translate(50px, -50%) scale(0.9);
  -webkit-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out; }

/* to left */
.reviews .text.review-item-left-enter {
  opacity: 0.01;
  -webkit-transform: translate(50px, -50%) scale(0.9);
          transform: translate(50px, -50%) scale(0.9); }

.reviews .text.review-item-left-enter.review-item-left-enter-active {
  opacity: 1;
  -webkit-transform: translate(0, -50%) scale(1);
          transform: translate(0, -50%) scale(1);
  -webkit-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out; }

.reviews .text.review-item-left-leave {
  opacity: 1; }

.reviews .text.review-item-left-leave.review-item-left-leave-active {
  opacity: 0.01;
  -webkit-transform: translate(-50px, -50%) scale(0.9);
          transform: translate(-50px, -50%) scale(0.9);
  -webkit-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out; }

section.statistics {
  position: relative;
  z-index: 2;
  padding-top: 75px;
  padding-bottom: 75px;
  overflow: hidden;
  display: flex;
  margin: 0 150px; }

section.statistics .stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50% - 60px);
  height: 200px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

section.statistics .stat-item:hover .stat-item__icon,
section.statistics .stat-item:hover .stat-item__description {
  opacity: .5; }

section.statistics .stat-item:hover .stat-item__value {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

section.statistics .stat-item .stat-item__icon-wrapper {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center; }

section.statistics .stat-item .stat-item__icon {
  height: 100%;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

section.statistics .stat-item .stat-item__value {
  display: flex;
  align-items: center;
  color: #3d7eff;
  font-size: 50px;
  font-family: Arial;
  font-weight: 800;
  white-space: nowrap;
  letter-spacing: -2px;
  margin-top: 10px;
  -webkit-transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55); }

section.statistics .stat-item .stat-item__value span {
  font-size: 30px;
  margin-left: 5px; }

section.statistics .stat-item .stat-item__description {
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 400;
  padding: 5px 15px;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

@media (min-width: 1700px) {
  section.statistics {
    padding-top: 125px;
    padding-bottom: 125px; } }

@media (max-width: 1300px) {
  section.statistics {
    padding-top: 50px;
    padding-bottom: 25px; }
  section.statistics .stat-item .stat-item__value {
    font-size: 40px; }
  section.statistics .stat-item .stat-item__icon-wrapper {
    width: 25px;
    height: 25px; } }

@media (max-width: 992px) {
  section.statistics {
    flex-wrap: wrap;
    padding-top: 50px;
    padding-bottom: 0px; }
  section.statistics .stat-item {
    width: 50%; }
  section.statistics .stat-item:nth-child(n+5) {
    display: none; }
  section.statistics .stat-item:nth-child(1) {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
  section.statistics .stat-item:nth-child(2) {
    border-bottom: 1px solid #ddd; }
  section.statistics .stat-item:nth-child(3) {
    border-right: 1px solid #ddd; }
  section.statistics .stat-item .stat-item__value {
    font-size: 50px; }
  section.statistics .stat-item .stat-item__icon-wrapper {
    width: 35px;
    height: 35px; } }

@media (max-width: 650px) {
  section.statistics {
    margin: 0; } }

@media (max-width: 576px) {
  section.statistics .stat-item .stat-item__value {
    font-size: 40px; }
  section.statistics .stat-item .stat-item__icon-wrapper {
    width: 25px;
    height: 25px; } }

section.contact {
  padding-bottom: 120px;
  position: relative; }

section.contact .contact-container {
  padding: 0 150px; }

section.contact .info,
section.contact .map {
  width: 30%; }

section.contact .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 350px;
  margin-bottom: 80px;
  padding: 0 100px;
  background-color: #202c35;
  color: white; }

section.contact .info .item {
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 10px 0; }

section.contact .info .item p {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  white-space: nowrap; }

section.contact .info .item img {
  margin-right: 20px;
  width: 35px;
  -webkit-filter: invert(1);
          filter: invert(1); }

section.contact .map {
  padding: 0; }

section.contact .map .map-container {
  width: 100%;
  height: 430px;
  overflow: hidden;
  position: relative;
  background-color: #eeeeee; }

section.contact .map iframe {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 400%;
  height: 100%; }

section.contact .map .info__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  font-size: 18px;
  color: white;
  background-color: #2664e3;
  text-decoration: none;
  position: relative;
  z-index: 2;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

section.contact .map .info__button p {
  margin: 0; }

section.contact .map .info__button p,
section.contact .map .info__button img {
  z-index: 1; }

section.contact .map .info__button:hover {
  background-color: #3d7eff; }

section.contact .map .info__button:hover img {
  -webkit-transform: translateX(10px);
          transform: translateX(10px); }

section.contact .map .info__button img {
  width: 25px;
  margin-left: 15px;
  -webkit-filter: invert(1);
          filter: invert(1);
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

section.contact .block {
  position: absolute;
  bottom: 200px;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 350px;
  background-color: #f4f4f4; }

section.contact .block .noise {
  width: 100%;
  height: 100%; }

@media (max-width: 1800px) {
  section.contact .info,
  section.contact .map {
    width: 50%; }
  section.contact .info {
    padding: 0 200px; } }

@media (max-width: 1500px) {
  section.contact .info {
    padding: 0 100px; } }

@media (max-width: 1200px) {
  section.contact .info {
    padding: 0 50px; } }

@media (max-width: 992px) {
  section.contact .contact-container {
    overflow: hidden; }
  section.contact .info,
  section.contact .map {
    width: 100%; }
  section.contact .map .map-container {
    height: 350px;
    overflow: visible; }
  section.contact .info {
    padding: 0 75px;
    margin-bottom: 0; } }

@media (max-width: 768px) {
  section.contact .contact-container {
    padding: 0 50px; }
  section.contact .info {
    margin-bottom: 0; }
  section.contact .block {
    bottom: auto;
    top: 50px; } }

@media (max-width: 576px) {
  section.contact .contact-container {
    padding: 0 15px; }
  section.contact .info .item img {
    width: 25px; }
  section.contact .info {
    padding: 0 25px; } }

@media (max-width: 380px) {
  section.contact .info {
    padding: 0 50px; } }

.contact-form-wrapper {
  padding: 0 150px; }

.contact-form {
  justify-content: space-around;
  background-color: #2664e3;
  width: 60%;
  margin: 50px auto;
  padding: 35px 75px; }

.contact-form a {
  color: white;
  text-decoration: underline; }

.contact-form a:hover {
  opacity: .5; }

@media (max-width: 1600px) {
  .contact-form {
    width: 100%; } }

@media (max-width: 850px) {
  .contact-form-wrapper {
    padding: 0 50px; } }

@media (max-width: 576px) {
  .contact-form-wrapper {
    padding: 0 15px; } }

@media (max-width: 480px) {
  .contact-form {
    padding: 35px; } }

.btn-loader {
  display: flex;
  align-items: center;
  position: relative;
  width: 150px;
  margin-right: auto;
  margin-left: auto; }

.btn-loader.success {
  background-color: rgba(198, 255, 0, 0.65); }

.btn-loader.fail {
  background-color: rgba(255, 0, 0, 0.65); }

.btn-loader p {
  margin: 0;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

.btn-loader p.hide {
  opacity: 0; }

.btn-loader .check, .btn-loader .fail {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

.btn-loader .check.hide, .btn-loader .fail.hide {
  -webkit-transform: translate(-50%) scale(0.5);
          transform: translate(-50%) scale(0.5);
  opacity: 0; }

.btn-loader .loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; }

.btn-loader .loader-circle {
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

.btn-loader .loader-circle.hide {
  -webkit-transform: translate(-50%, -50%) scale(0.5);
          transform: translate(-50%, -50%) scale(0.5);
  opacity: 0; }

.btn-loader .loader-circle .circular {
  height: 35%;
  width: 35%; }

.form-input-group {
  position: relative;
  margin-bottom: 25px; }

.form-input-group input,
.form-input-group textarea {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid white;
  background-color: transparent;
  color: white; }

.form-input-group.is-invalid input,
.form-input-group.is-invalid textarea {
  border-bottom: 1px solid #F44336; }

.form-input-group input:focus,
.form-input-group textarea:focus {
  outline: none; }

/* LABEL ======================================= */
.form-input-group label {
  color: white;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all; }

.form-input-group.is-invalid label {
  color: #F44336 !important; }

/* active state */
.form-input-group input:focus ~ label,
.form-input-group textarea:focus ~ label,
.form-input-group.form-input-group--focus label {
  top: -20px;
  font-size: 14px; }

/* BOTTOM BARS ================================= */
.form-input-group .bar {
  position: relative;
  display: block; }

.form-input-group .bar:before,
.form-input-group .bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: white;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all; }

.form-input-group.is-invalid .bar:before,
.form-input-group.is-invalid .bar:after {
  background: #F44336; }

.form-input-group .bar:before {
  left: 50%; }

.form-input-group .bar:after {
  right: 50%; }

/* active state */
.form-input-group input:focus ~ .bar:before,
.form-input-group textarea:focus ~ .bar:before,
.form-input-group input:focus ~ .bar:after,
.form-input-group textarea:focus ~ .bar:after {
  width: 50%; }

/* INVALID FEEDBACK ============================= */
.form-input-group .invalid-feedback {
  display: block;
  opacity: 0;
  height: 0;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

.form-input-group.is-invalid .invalid-feedback {
  opacity: 1;
  height: 20px; }

.form-input-group__checkbox label {
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  cursor: pointer;
  display: flex; }

.form-input-group__checkbox label span {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  color: white; }

.form-input-group__checkbox label span:first-child {
  position: relative;
  min-width: 18px;
  height: 18px;
  border-radius: 3px;
  -webkit-transform: scale(1);
          transform: scale(1);
  vertical-align: middle;
  border: 1px solid white;
  margin-right: 8px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.form-input-group__checkbox label span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #2664e3;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.form-input-group__checkbox label span:first-child::before {
  content: "";
  width: 100%;
  height: 100%;
  background: white;
  display: block;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 1;
  border-radius: 50%; }

.form-input-group__checkbox label:hover span:first-child {
  background-color: rgba(255, 255, 255, 0.1); }

.form-input-group__checkbox input:checked + label span:first-child {
  background: white;
  border-color: white;
  -webkit-animation: wave 0.4s ease;
          animation: wave 0.4s ease; }

.form-input-group__checkbox input:checked + label span:first-child svg {
  stroke-dashoffset: 0; }

.form-input-group__checkbox input:checked + label span:first-child::before {
  -webkit-transform: scale(3.5);
          transform: scale(3.5);
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease; }

@-webkit-keyframes wave {
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); } }

@keyframes wave {
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); } }

.form-input-group__checkbox label span:last-child {
  margin-top: -2px; }

/* INVALID FEEDBACK ============================= */
.form-input-group__checkbox .invalid-feedback {
  display: block;
  opacity: 0;
  height: 0;
  text-align: start;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

.form-input-group__checkbox.is-invalid .invalid-feedback {
  opacity: 1;
  height: 20px; }

.contact table td:first-child {
  background-color: #f7f7f7;
  font-weight: 600; }

.contact .table-title {
  color: #fff;
  text-transform: uppercase;
  background-color: rgba(54, 48, 74, 0.7);
  font-weight: 600;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px; }

.contact-fluid {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding: 10px; }

.contact-fluid .contact-item-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 75px; }

.contact-fluid .contact-item-wrapper .contact-item {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  padding: 15px;
  color: #5d5d5d;
  min-width: 300px;
  text-decoration: none; }

.contact-fluid .contact-item-wrapper .contact-item img {
  margin-right: 15px;
  width: 40px;
  height: 40px; }

.contact-fluid .contact-item-wrapper .contact-item p {
  margin: 0; }

.contact-fluid .contact-item-wrapper .contact-item .contact-item__title {
  font-size: 13px; }

.contact-fluid .contact-item-wrapper .contact-item .contact-item__value {
  display: flex;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  will-change: opacity;
  text-decoration: none;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

.contact-fluid .contact-item-wrapper .contact-item a.contact-item__value:hover {
  opacity: .5; }

@media (max-width: 992px) {
  .contact-wrapper .map-wrapper {
    height: 350px; } }

@media (max-width: 768px) {
  .contact-wrapper .map-wrapper .map {
    width: 300%; } }

@media (max-width: 480px) {
  .contact-wrapper .map-wrapper .map {
    width: 400%; }
  .contact-fluid .contact-item-wrapper {
    justify-content: flex-start; } }

/* All posts */
.posts {
  margin-bottom: 25px; }

@media (min-width: 992px) {
  .posts {
    margin-top: 25px; } }

/* Single post */
.post {
  max-width: 740px;
  padding: 20px;
  margin: auto;
  margin-bottom: 50px; }

.post h1, h2, h3, h4, h5 {
  font-weight: 600; }

.post h4 {
  margin-top: 50px; }

.post .post-share-buttons {
  position: absolute;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  left: 0;
  display: block;
  opacity: 1;
  margin-left: -120px;
  height: 0; }

.post .post-share-buttons button,
.post .post-share-buttons .SocialMediaShareButton {
  width: 45px;
  height: 45px;
  margin: 0 0 10px 0;
  border: none;
  background-color: #3d7eff;
  box-shadow: 0 0 10px -1px #3d7eff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

.post .post-share-buttons button:hover,
.post .post-share-buttons .SocialMediaShareButton:hover {
  background-color: #2664e3;
  box-shadow: 0 0 10px 0px #2664e3; }

.post .post-share-buttons .button:hover i,
.post .post-share-buttons .SocialMediaShareButton:hover img {
  opacity: 1; }

.post .post-share-buttons button:active,
.post .post-share-buttons .SocialMediaShareButton:active {
  background-color: #2664e3; }

.post .post-share-buttons button a,
.post .post-share-buttons button i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%; }

.post .post-share-buttons button i {
  color: white;
  will-change: opacity;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

.post .post-share-buttons .SocialMediaShareButton img {
  width: 50%;
  height: 50%;
  -webkit-filter: invert(1);
          filter: invert(1);
  will-change: opacity;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

.post img {
  max-width: 100%;
  height: auto; }

.post blockquote {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 40px;
  -webkit-margin-end: 40px; }

.post .alignleft, img.alignleft {
  margin: 0.5em 1em 0.5em 0;
  display: inline;
  float: left; }

.post .alignright, img.alignright {
  margin: 0.5em 0 0.5em 1em;
  display: inline;
  float: right; }

.post .aligncenter, img.aligncenter {
  margin-right: auto;
  margin-left: auto;
  display: block;
  clear: both; }

@media (max-width: 576px) {
  .post .alignleft, .post img.alignleft, .post .alignright, .post img.alignright {
    margin: 0.5em auto;
    display: block;
    float: none; }
  .post img.alignleft, img.alignright {
    object-fit: contain; } }

/* Table */
.post table {
  overflow: hidden;
  border: none;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat';
  font-weight: 300;
  margin-bottom: 1rem;
  position: relative; }

.post table thead td {
  border: none !important; }

.post table td {
  border: 1px solid #f0f0f0 !important; }

.post table td strong {
  font-weight: 600; }

.post table tr:hover {
  background-color: #f2f2f2; }

.post table td, .post table th {
  position: relative;
  border: none; }

.post table td:hover::after,
.post table th:hover::after {
  content: '';
  background-color: #f2f2f2;
  position: absolute;
  left: 0;
  height: 10000px;
  top: -5000px;
  width: 100%;
  z-index: -1; }

.post table td:hover {
  background-color: #5badff;
  color: #ffffff; }

.post table td:hover a {
  color: #ffffff; }

.post table thead th:hover,
.post table thead td:hover {
  background-color: rgba(54, 48, 74, 0.85);
  color: #ffffff; }

.post table td, .post table tr {
  height: auto !important; }

.post table td, .post table th, .post table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center; }

.post table thead td, .post table th {
  color: #fff;
  background-color: rgba(54, 48, 74, 0.7);
  font-weight: 600; }

/* Post item */
.post-item--small {
  display: flex;
  width: calc(100% / 3);
  color: #000000;
  padding: 10px;
  position: relative; }

.post-item--small.lg {
  width: calc(100% / 2); }

.post-item--small.lg .image {
  height: 250px; }

.post-item--small a {
  position: relative;
  text-decoration: none; }

.post-item--small:hover .image {
  opacity: .75; }

.post-item--small a {
  width: 100%; }

.post-item--small .image {
  width: 100%;
  height: 175px;
  background-color: #eeeeee;
  -webkit-transition: .1s ease-in-out;
  transition: .1s ease-in-out; }

.post-item--small .image img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.post-item--small .data {
  padding-bottom: 30px; }

.post-item--small .data h1 {
  font-weight: 700;
  font-size: 20px;
  color: #000000;
  padding: 10px 0;
  margin: 0; }

.post-item--small .data .first-paragraph {
  font-size: 17px;
  color: #786c5d; }

.post-item--small .data .date {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #000000; }

.post-share-buttons {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
  opacity: 0;
  display: flex;
  will-change: opacity;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out; }

.post-item--small:hover .post-share-buttons {
  opacity: 1; }

.post-share-buttons .SocialMediaShareButton {
  width: 35px;
  height: 35px;
  margin: 2.5px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

.post-share-buttons .SocialMediaShareButton:hover {
  border-color: rgba(255, 255, 255, 0.5); }

.post-share-buttons .SocialMediaShareButton:hover img {
  opacity: .5; }

.post-share-buttons .SocialMediaShareButton:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  background-color: #ffffff; }

.post-share-buttons .SocialMediaShareButton:active img {
  -webkit-filter: none;
          filter: none; }

.post-share-buttons .SocialMediaShareButton img {
  width: 50%;
  height: 50%;
  -webkit-filter: invert(1);
          filter: invert(1);
  will-change: opacity;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

@media (max-width: 1200px) {
  .post-item--small {
    width: calc(100% / 3); } }

@media (max-width: 850px) {
  .post-item--small {
    width: calc(100% / 2); }
  .post-item--small.lg .image {
    height: 175px; } }

@media (max-width: 576px) {
  .posts {
    padding: 0; }
  .post-item--small, .post-item--small.lg {
    width: calc(100% / 1);
    padding: 0; }
  .post-item--small .data {
    padding: 0 14px; }
  .post-item--small .data .date {
    position: relative;
    margin: 1rem 0; }
  .post-item--small .image, .post-item--small.lg .image {
    height: 200px; } }

.page-item.disabled {
  pointer-events: none; }

.products-container {
  max-width: 900px;
  padding: 0; }

.products {
  max-width: 900px;
  min-height: 400px;
  margin: 0 auto 50px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start; }

.product-item {
  position: relative;
  width: calc(50% - 20px);
  padding: 15px;
  margin: 10px;
  display: flex;
  border: 1px solid #dddddd;
  text-decoration: none;
  overflow: hidden;
  will-change: border-color, box-shadow, transform;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

.product-item:hover {
  border-color: #444444;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  text-decoration: none; }

.product-item:hover .product-item-button {
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.product-item .title {
  margin-left: 15px;
  color: #5d5d5d; }

.product-item img {
  background-color: #ffffff;
  width: 100px;
  height: 80px;
  object-fit: cover; }

.product-item .product-item-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: #2664e3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

.product-item .product-item-button i {
  color: white; }

@media (max-width: 650px) {
  .product-item {
    width: 100%; } }

.product-parent-link {
  color: #5d5d5d;
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  font-size: 14px; }

.product-parent-link:hover {
  text-decoration: none;
  color: #5d5d5d; }

.product-parent-link .icon-wrapper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: -8px;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out; }

.product-parent-link:hover .icon-wrapper {
  background-color: rgba(0, 0, 0, 0.1); }

.product-parent-link:active .icon-wrapper {
  background-color: #2664e3; }

.product-parent-link:active .icon-wrapper i {
  color: white; }

.product-parent-link .icon-wrapper i {
  width: 25px;
  height: 25px; }

