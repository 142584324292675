@import '../../../styles/colors.scss';

.contact-form-wrapper {
  padding: 0 150px;
}

.contact-form {
  justify-content: space-around;
  background-color: $accent-dark;
  width: 60%;
  margin: 50px auto;
  padding: 35px 75px;
}

.contact-form a {
  color: white;
  text-decoration: underline;
}

.contact-form a:hover {
  opacity: .5;
}

@media (max-width: 1600px) {
  .contact-form {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .contact-form-wrapper {
    padding: 0 50px;
  }
}

@media (max-width: 576px) {
  .contact-form-wrapper {
    padding: 0 15px;
  }
}

@media (max-width: 480px) {
  .contact-form {
    padding: 35px;
  }
}