@import '../../../styles/colors.scss';

header {
  position: relative;
  position: sticky;
  top: 0;
  z-index: 998;
  border-bottom: 1px solid transparent;
  transition: .25s ease-in-out;
}

header.header--small  {
  background-color: rgba(255, 255, 255, 0.85);
  border-color:rgba(0, 0, 0, 0.1);
}

header.header--small nav .nav__item--logo::before {
  border-top-color: $primary;
}

header.header--small nav .nav__item--logo .nav__item--logo--dark {
  opacity: 0;
}

header.header--small nav .nav__item--logo::after {
  display: block;
}

header.header--small nav .nav__item--search,
header.header--small nav .nav__item--mobile {
  color: $dark;
}


nav {
  display: flex;
  align-items: center;
  height: 60px;
  margin-right: 150px;
  transition: .25s ease-in-out;
}

nav .nav__item {
  color: #000000;
  padding: 10px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  position: relative;
  font-weight: 600;
  user-select: none;
}

nav .nav__item--hover:hover::after {
  transform: translate(-50%) scaleX(1);
}

nav .nav__item--hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 50%;
  z-index: -1;
  background-color: $accent;
  transform-origin: 50%;
  will-change: transform;
  transform: translate(-50%) scaleX(0);
  transition: .15s ease-in-out;
}

nav .nav__item--logo {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 55px;
  margin: 0 auto 0 -35px;
  width: auto;
  position: relative;
}

nav .nav__item--logo:hover img {
  opacity: .5;
}

nav .nav__item--logo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-top: 60px solid white;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  height: 0;
  width: 100%;
  transition: .15s ease-in-out;
}

nav .nav__item--logo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  border-top: 60px solid rgba(0, 0, 0, 0.25);
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  height: 0;
  width: 100%;
  filter: blur(10px);
  will-change: border-top;
  transition: .15s ease-in-out;
}

nav .nav__item--logo img {
  width: 110px;
  will-change: opacity;
  transition: .15s ease-in-out;
}

nav .nav__item--logo .nav__item--logo--dark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 150ms ease-in-out;
}

nav .nav__item--mobile {
  cursor: pointer;
  width: auto;
  color: white;
}
nav .nav__item--mobile:hover i {
  opacity: .5;
}
nav .nav__item--mobile i {
  font-size: 25px;
  transition: .15s ease-in-out;
}

nav .nav__item--search {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  user-select: none;
  transition: .15s ease-in-out;
}

nav .nav__item--search:hover i {
  background-color: rgba(0, 0, 0, 0.1);
}

nav .nav__item--search i {
  font-size: 30px;
  border-radius: 50%;
  padding: 9px;
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 993px) {
  nav .nav__item--mobile {
    display: none;
  }

  nav .nav__item--search {
    color: $dark;
  }
}

@media (max-width: 992px) {
  header nav {
    margin-right: 65px;
  }
  nav .nav__item {
    order: 1;
  }
  nav .nav__item--logo {
    order: 0;
    margin: 0 auto 0 -35px;
  }
  nav .nav__item--logo::after {
    display: none;
  }
  nav .nav__item--search {
    width: auto;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  nav .nav__item--search,
  nav .nav__item--mobile {
    color: $dark;
  }
}

@media (max-width: 992px) {
  header.header--small nav {
    margin-right: 0;
  }
  header nav {
    margin-right: 0;
  }
  nav .nav__item--search {
    margin-right: 50px;
  }
  nav .nav__item--hover {
    display: none;
  }
  header.header--small nav .nav__item--search,
  header.header--small nav .nav__item--mobile {
    color: $dark;
  }
}

@media (max-width: 480px) {
  header.header--small nav {
    background-color: $primary;
    box-shadow: 0 0 25px rgba(0,0,0,0.25);
  }
  header.header--small nav .nav__item--mobile::after {
    box-shadow: none;
  }
  header.header--small nav .nav__item--logo::after {
    display: none;
  }
  header.header--small nav .nav__item--mobile {
    color: #ffffff;
  }
  header.header--small nav .nav__item--search i {
    color: #ffffff;
  }
}