@import '../../../styles/colors.scss';

.searchbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 60px;
  background-color: #546475;
  display: flex;
  box-shadow: 0 0 75px rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100%);
  will-change: opacity;
  transition: visibility 0s linear 250ms, all 250ms ease-in-out;
}

.searchbar-wrapper.visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0s;
}

.searchbar-wrapper .close-btn,
.searchbar-wrapper .search-icon {
  background-color: #2f3c4a;
  color: #ffffff;
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  will-change: backround-color;
  transition: 150ms ease-in-out;
}

.searchbar-wrapper .close-btn:hover,
.searchbar-wrapper .search-icon:hover {
  background-color: #445261;
}

.searchbar-wrapper .close-btn i,
.searchbar-wrapper .search-icon i {
  transition: 300ms ease-in-out 50ms;
}

.searchbar-wrapper .search-icon {
  background-color: $primary;
}
.searchbar-wrapper .search-icon:hover {
  background-color: $primary-light;
}

.searchbar-wrapper.visible .close-btn i {
  transform: rotate(90deg);
}

.searchbar-wrapper .search-input {
  flex-grow: 1;
  position: relative;
}

.searchbar-wrapper .search-input .placeholder {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 25px;
  font-size: 20px;
  color: #ffffff;
  transform: translateY(10px);
  opacity: .75;
  transition: transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1) 150ms,
              all .15s ease-in-out;
}

.searchbar-wrapper.visible .search-input .placeholder {
  transform: translateY(0);
}

.searchbar-wrapper .search-input:hover .placeholder {
  opacity: .5;
}

.searchbar-wrapper .search-input .placeholder.hide {
  opacity: 0;
}

.searchbar-wrapper .search-input form {
  width: 100%;
  height: 100%;
}

.searchbar-wrapper .search-input input {
  width: 100%;
  height: 100%;
  padding: 0 25px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #ffffff;
  font-size: 20px;
}

@media (max-width: 768px) {
  .searchbar-wrapper .search-icon {
    display: none;
  }
}