@import '../../styles/colors.scss';

.contact table td:first-child {
  background-color: #f7f7f7;
  font-weight: 600;
}

.contact .table-title {
  color: #fff;
  text-transform: uppercase;
  background-color: rgba(54, 48, 74, 0.7);
  font-weight: 600;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.contact-fluid {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding: 10px;
}

.contact-fluid .contact-item-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 75px;
}

.contact-fluid .contact-item-wrapper .contact-item {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  padding: 15px;
  color: $font-color;
  min-width: 300px;
  text-decoration: none;
}

.contact-fluid .contact-item-wrapper .contact-item img {
  margin-right: 15px;
  width: 40px;
  height: 40px;
}

.contact-fluid .contact-item-wrapper .contact-item p {
  margin: 0;
}

.contact-fluid .contact-item-wrapper .contact-item .contact-item__title {
  font-size: 13px;
}

.contact-fluid .contact-item-wrapper .contact-item .contact-item__value {
  display: flex;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  will-change: opacity;
  text-decoration: none;
  transition: .15s ease-in-out;
}

.contact-fluid .contact-item-wrapper .contact-item a.contact-item__value:hover {
  opacity: .5;
}


@media (max-width: 992px) {
  .contact-wrapper .map-wrapper {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .contact-wrapper .map-wrapper .map {
    width: 300%;
  }
}
@media (max-width: 480px) {
  .contact-wrapper .map-wrapper .map {
    width: 400%;
  }
  .contact-fluid .contact-item-wrapper {
    justify-content: flex-start;
  }
}