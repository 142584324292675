@import '../../../styles/colors.scss';

footer {
  background-color: $dark;
  padding: 50px 0;
  position: relative;
}

footer .logo {
  width: 110px;
  transition: .15s ease-in-out;
}
footer .logo:hover {
  opacity: .5;
}

footer .rights {
  color: #747e88;
  font-size: 15px;
}

footer .rights .avalon {
  width: 38px;
  height: 38px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin-left: 15px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 0 1px transparent;
  opacity: .5;
  will-change: opacity;
  transition: .15s ease-in-out;
}
footer .rights .avalon img {
  width: 100%;
  height: 100%;
  filter: invert(1);
}
footer .rights .avalon:hover {
  opacity: 1;
}

footer .contact-item {
  display: flex;
  align-items: center;
  margin: 15px 0;
  text-decoration: none;
}
footer .contact-item:hover p {
  opacity: .5;
}

footer .contact-item p {
  margin: 0;
  white-space: nowrap;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  will-change: opacity;
  transition: .15s ease-in-out;
}

footer .contact-item  img,
footer .contact-item  .icon-placeholder {
  margin-right: 20px;
  width: 20px;
  filter: invert(1);
}

footer .to-up-btn {
  position: absolute;
  top: -25px;
  right: 100px;
  z-index: 10;
  background-color: $primary;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(0,0,0,0.25);
  will-change: background-color;
  transition: transform .35s ease-in-out, all .15s ease-in-out;
}
footer .to-up-btn:hover {
  background-color: $primary-dark;
  transform: translateY(-10px);
}
footer .to-up-btn i {
  font-size: 25px;
  color: #ffffff;
}

@media (max-width: 768px) {
  footer .to-up-btn {
    right: 25px;
  }
}