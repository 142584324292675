@import '../../../styles/colors.scss';

.reviews {
  padding: 75px 0 100px 0;
  position: relative;
  overflow: hidden;
}

.reviews .reviews-wrapper {
  padding: 0 150px;
  position: relative;
  z-index: 1;
}

.reviews .background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

.reviews .noise {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

.reviews .particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px 0;
  transition: .5s ease-in-out;
}
.reviews .particles.disabled {
  opacity: 0;
  transition: 0s;
}
.reviews .particles canvas {
  width: 100%;
  height: 100%;
}

.reviews .arrow {
  position: absolute;
  bottom: -50px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  font-weight: 100;
  width: 50px;
  height: 50px;
  color: white;
  background-color: $accent-dark;
  user-select: none;
  cursor: pointer;
  will-change: opacity;
  transition: .15s ease-in-out;
}

.reviews .arrow.arrow--right {
  left: 50px;
  background-color: $accent;
}

.reviews .arrow.disabled {
  pointer-events: none;
}

.reviews .arrow:hover {
  opacity: .75;
}

.reviews .image-container,
.reviews .text-container {
  width: 30%;
}

.reviews .text-container {
  position: relative;
  height: 270px;
  background-color: $dark;
}

.reviews .text {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 75px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  box-shadow: 0 0 75px rgba(0, 0, 0, 0.05);
}
.reviews .text .title {
  font-style: italic;
  font-size: 14px;
}
.reviews .text .title::before {
  content: "- "
}
.reviews .text .title::after {
  content: " -"
}


.review-index-container {
  position: absolute;
  bottom: -8px;
  right: 0;
  margin: 0;
}
.review-index {
  position: relative;
  width: 30px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.2);
}
.review-index::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $accent-dark;
  opacity: 0;
  transform: scaleX(0);
  transition: .75s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.review-index.active::before {
  opacity: 1;
  transform: scaleX(1);
}


.reviews .image-container {
  height: 320px;
  padding: 0;
}

.reviews .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@keyframes review-index-active {
  0% {
    background-color: #d9d9d9;
  }
  100% {
    background-color: $primary;
  }
}


@media (max-width: 1600px) {
  .reviews .image-container,
  .reviews .text-container {
    width: 50%;
  }
}

@media (max-width: 1100px) {
  .reviews .image-container,
  .reviews .text-container {
    width: 100%;
    height: 270px;
  }
}

@media (max-width: 992px) {
  .reviews .text {
    padding: 25px 35px;
  }
}

@media (max-width: 768px) {
  .reviews .image-container {
    height: 220px;
  }
  .reviews .reviews-wrapper {
    padding: 0 50px;
  }
}

@media (max-width: 576px) {
  .reviews .reviews-wrapper {
    padding: 0 15px;
  }
  .reviews .arrow {
    font-size: 75px;
  }
  .reviews .text {
    margin: 0 15px;
    width: calc(100% - 30px);
  }
  .reviews .image-container {
    order: 1;
    height: 220px;
  }
  .reviews .text-container {
    order: 1;
    height: 320px;
  }
  .reviews .arrow--left {
    order: 2;
  }
  .reviews .arrow--right {
    order: 3;
  }
}


/* Fade in classes */
/* to right */
.reviews .text.review-item-right-enter {
  opacity: 0.01;
  transform: translate(-50px, -50%) scale(.9);
}

.reviews .text.review-item-right-enter.review-item-right-enter-active {
  opacity: 1;
  transform: translate(0, -50%) scale(1);
  transition: 500ms ease-in-out;
}

.reviews .text.review-item-right-leave {
  opacity: 1;
}

.reviews .text.review-item-right-leave.review-item-right-leave-active {
  opacity: 0.01;
  transform: translate(50px, -50%) scale(.9);
  transition: 500ms ease-in-out;
}


/* to left */
.reviews .text.review-item-left-enter {
  opacity: 0.01;
  transform: translate(50px, -50%) scale(.9);
}

.reviews .text.review-item-left-enter.review-item-left-enter-active {
  opacity: 1;
  transform: translate(0, -50%) scale(1);
  transition: 500ms ease-in-out;
}

.reviews .text.review-item-left-leave {
  opacity: 1;
}

.reviews .text.review-item-left-leave.review-item-left-leave-active {
  opacity: 0.01;
  transform: translate(-50px, -50%) scale(.9);
  transition: 500ms ease-in-out;
}
