@import '../../../styles/colors.scss';

.scroll-indicator-wrapper {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: calc(50% - 30px);
  z-index: 1;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .15s ease-in-out;
}

.scroll-indicator-wrapper:hover .scroll-indicator {
  border-color: $primary-light;
}
.scroll-indicator-wrapper:hover .scroll-indicator .scroll-wheel {
  background-color: $primary-light;
}

.scroll-indicator-wrapper:active {
  transform: scale(.97);
}

.scroll-indicator {
  width: 25px;
  height: 40px;
  border: 2px solid $primary;
  border-radius: 15px;
  text-align: center;
}

.scroll-indicator .scroll-wheel {
  height: 8px;
  margin: 2px auto 0;
  display: block;
  width: 3px;
  background-color: $primary;
  border-radius: 1.5px;
  will-change: opacity, transform;
  animation: 1.6s ease infinite wheel-up-down;
}

@keyframes wheel-up-down {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(8px);
    opacity: 0;
  }
}

@media (max-width: 992px) {
  .scroll-indicator-wrapper {
    display: none;
  }
}