.form-input-group {
  position: relative;
  margin-bottom: 25px;
}

.form-input-group input,
.form-input-group textarea {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid white;
  background-color: transparent;
  color: white;
}

.form-input-group.is-invalid input,
.form-input-group.is-invalid textarea {
  border-bottom: 1px solid #F44336;
}

.form-input-group input:focus,
.form-input-group textarea:focus {
  outline: none;
}

/* LABEL ======================================= */

.form-input-group label {
  color: white;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.form-input-group.is-invalid label {
  color: #F44336 !important;
}

/* active state */

.form-input-group input:focus~label,
.form-input-group textarea:focus~label,
.form-input-group.form-input-group--focus label {
  top: -20px;
  font-size: 14px;
}

/* BOTTOM BARS ================================= */

.form-input-group .bar {
  position: relative;
  display: block;
}

.form-input-group .bar:before,
.form-input-group .bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: white;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.form-input-group.is-invalid .bar:before,
.form-input-group.is-invalid .bar:after {
  background: #F44336;
}

.form-input-group .bar:before {
  left: 50%;
}

.form-input-group .bar:after {
  right: 50%;
}

/* active state */

.form-input-group input:focus~.bar:before,
.form-input-group textarea:focus~.bar:before,
.form-input-group input:focus~.bar:after,
.form-input-group textarea:focus~.bar:after {
  width: 50%;
}

/* INVALID FEEDBACK ============================= */
.form-input-group .invalid-feedback {
  display: block;
  opacity: 0;
  height: 0;
  transition: .25s ease-in-out;
}

.form-input-group.is-invalid .invalid-feedback {
  opacity: 1;
  height: 20px;
}