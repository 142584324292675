@import '../../../styles/colors.scss';

section.contact {
  padding-bottom: 120px;
  position: relative;
}

section.contact .contact-container {
  padding: 0 150px;
}

section.contact .info,
section.contact .map {
  width: 30%;
}

section.contact .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 350px;
  margin-bottom: 80px;
  padding: 0 100px;
  background-color: $dark;
  color: white;
}

section.contact .info .item {
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 10px 0;
}
section.contact .info .item p {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  white-space: nowrap;
}
section.contact .info .item img {
  margin-right: 20px;
  width: 35px;
  filter: invert(1);
}

section.contact .map {
  padding: 0;
}

section.contact .map .map-container {
  width: 100%;
  height: 430px;
  overflow: hidden;
  position: relative;
  background-color: #eeeeee;
}

section.contact .map iframe {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 400%;
  height: 100%;
}

section.contact .map .info__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  font-size: 18px;
  color: white;
  background-color: $accent-dark;
  text-decoration: none;
  position: relative;
  z-index: 2;
  transition: .15s ease-in-out;
}

section.contact .map .info__button p {
  margin: 0;
}

section.contact .map .info__button p,
section.contact .map .info__button img {
  z-index: 1;
}

section.contact .map .info__button:hover {
  background-color: $accent;
}

section.contact .map .info__button:hover img {
  transform: translateX(10px);
}

section.contact .map .info__button img {
  width: 25px;
  margin-left: 15px;
  filter: invert(1);
  transition: .25s ease-in-out;
}


section.contact .block {
  position: absolute;
  bottom: 200px;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 350px;
  background-color: #f4f4f4;
}

section.contact .block .noise {
  width: 100%;
  height: 100%;
}

@media (max-width: 1800px) {
  section.contact .info,
  section.contact .map {
    width: 50%;
  }
  section.contact .info {
    padding: 0 200px;
  }
}

@media (max-width: 1500px) {
  section.contact .info {
    padding: 0 100px;
  }
}

@media (max-width: 1200px) {
  section.contact .info {
    padding: 0 50px;
  }
}

@media (max-width: 992px) {
  section.contact .contact-container {
    overflow: hidden;
  }
  section.contact .info,
  section.contact .map {
    width: 100%;
  }
  section.contact .map .map-container {
    height: 350px;
    overflow: visible;
  }
  section.contact .info {
    padding: 0 75px;
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  section.contact .contact-container {
    padding: 0 50px;
  }
  section.contact .info {
    margin-bottom: 0;
  }
  section.contact .block {
    bottom: auto;
    top: 50px;
  }
}

@media (max-width: 576px) {
  section.contact .contact-container {
    padding: 0 15px;
  }
  section.contact .info .item img {
    width: 25px;
  }
  section.contact .info {
    padding: 0 25px;
  }
}

@media (max-width: 380px) {
  section.contact .info {
    padding: 0 50px;
  }
}